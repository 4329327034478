import cls from 'classnames';
import StatusBadges from 'Components/StatusBadges';
import React from 'react';

const ListItem = ({ mainTitle, subTitles = [], status, action = null, as = 'div', ...props }) => {
  const Element = as;

  return (
    <div className="block flex w-full items-center justify-between gap-4 py-2">
      <Element className={cls('py-2', action ? 'max-w-2/3' : 'w-full')} {...props}>
        <div className="flex justify-between">
          <div className="grow text-sm font-semibold text-primary">{mainTitle}</div>
          {!action && status && (
            <div className="ml-2 w-full text-right text-sm">
              <StatusBadges status={status} />
            </div>
          )}
        </div>
        {subTitles && (
          <div className="mt-2 flex flex-col items-start text-xs text-gray-600">
            {subTitles.map((subTitle, index) => (
              <div key={index}>{subTitle}</div>
            ))}
          </div>
        )}
      </Element>
      {action && (
        <div className="flex flex-col items-end space-y-2 text-sm">
          <StatusBadges status={status} />
          {action}
        </div>
      )}
    </div>
  );
};

export default ListItem;
