import { mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';

const SyncSpinner = ({ loading, children = <></> }) =>
  loading ? (
    <div className="fixed relative inset-0 z-50 flex items-center justify-center">
      <Icon path={mdiLoading} size={3} className="animate-spin" />
    </div>
  ) : (
    children
  );

export default SyncSpinner;
