import { mdiCalendar, mdiPlus, mdiSync } from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import * as Sentry from '@sentry/react';
import cls from 'classnames';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import ListItem from 'Components/ListItem';
import ListItemWrapper from 'Components/ListItemWrapper';
import SyncSpinner from 'Components/SyncSpinner';
import WarningOfDataLimit from 'Components/WarningOfDataLimit';
import { format, parseJSON } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';

const Reports = () => {
  usePageTitle('Forms');

  const isOnline = useOnlineStatus();
  const [reports, setReports] = useState([]);
  const [searchedReports, setSearchedReportsDefault] = useState([]);
  const [syncing, setSyncing] = useState(false);
  const [search, setSearch] = useState('');

  const setSearchedReports = (value) => {
    if (!Array.isArray(value)) {
      Sentry.addBreadcrumb({
        level: 'debug',
        category: 'searchedReports',
        data: { searchedReports: value },
      });
      throw new Error(`searchedReports is a ${typeof value}`);
    }

    setSearchedReportsDefault(value);
  };

  const sync = async () => {
    if (isOnline && !syncing) {
      setSyncing(true);

      Sentry.addBreadcrumb({
        category: 'searchedReports',
        level: 'debug',
        message: 'Loading reports from api',
      });
      const reportsQueried = await api.syncReports();
      setReports(reportsQueried);
      setSearchedReports(reportsQueried);
    } else {
      Sentry.addBreadcrumb({
        category: 'searchedReports',
        level: 'debug',
        message: 'Loading reports from valueStore',
      });
      const reportsQueried = await valueStore.getArray('reports');
      setReports(reportsQueried);
      setSearchedReports(reportsQueried);
    }

    setSyncing(false);
  };

  useEffect(() => {
    if (search) {
      Sentry.addBreadcrumb({
        category: 'searchedReports',
        level: 'debug',
        message: 'Loading reports from reports state filtering',
      });
      setSearchedReports(reports.filter((report) => report.name?.toLowerCase().includes(search.toLowerCase())));
    } else {
      Sentry.addBreadcrumb({
        category: 'searchedReports',
        level: 'debug',
        message: 'Loading reports from reports state parameter',
      });
      setSearchedReports(reports);
    }
  }, [search]);

  useEffect(() => {
    sync();
  }, []);

  return (
    <div className="m-4">
      <SyncSpinner loading={syncing} />

      <PrimaryButton as={Link} to={route('reports.select-form-template')} className="mb-12 w-full">
        <Icon path={mdiPlus} size={1} className="mr-1" /> Add Form
      </PrimaryButton>

      <ListItemWrapper searchName="Forms" setSearch={setSearch} searchDisabled={syncing}>
        {searchedReports?.map((report, index) => (
          <ListItem
            key={index}
            mainTitle={
              <div className="flex items-center gap-2">
                {(report.new || report.updated) && (
                  <div className={cls(report.updated ? 'bg-orange-500' : 'bg-red-500', 'rounded-2xl p-1')}>
                    <Icon path={mdiSync} size={0.7} className="text-white" />
                  </div>
                )}
                {report.name}
              </div>
            }
            subTitles={[
              <>
                <div className="mb-3">
                  <p className="text-sm text-gray-900">
                    <span className="font-semibold text-gray-500">Job: </span>
                    {report?.job?.name}
                  </p>
                  {report.description && (
                    <p className="text-sm text-gray-900">
                      <span className="font-semibold text-gray-500">Description: </span>
                      {report.description}
                    </p>
                  )}
                </div>
                <div className="flex space-x-1 text-xs text-gray-600">
                  <Icon path={mdiCalendar} size={0.6} />
                  <p>
                    {report.created_at === 'now' || !report.created_at
                      ? format(Date.now(), 'yyyy/MM/dd hh:mm aaa')
                      : format(parseJSON(report.created_at), 'yyyy/MM/dd hh:mm aaa')}
                  </p>
                </div>
              </>,
            ]}
            as={Link}
            to={report.id || report.temp_id ? route('reports.edit', { id: report.id || report.temp_id }) : '#'}
            status={report.id ? 'New' : 'To be synced'}
          />
        ))}
      </ListItemWrapper>
      <WarningOfDataLimit />
    </div>
  );
};

export default Reports;
