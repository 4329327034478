import useOnlineStatus from '@rehooks/online-status';
import MultiField from 'Components/Forms/MultiField';
import { useEffect, useMemo, useState } from 'react';
import api from 'Services/api';
import valueStore from 'Services/valueStore';

const EquipmentList = ({ equipment: initialEquipment, push, insert, required = false }) => {
  const isOnline = useOnlineStatus();
  const [suggestions, setSuggestions] = useState([]);

  const sync = () => {
    const getEquipment = async () => await api.fetchEquipment();

    getEquipment().then(async (equipment) => {
      const values = equipment.map((equipment) => equipment.description);
      const equipmentArray = initialEquipment.map((equipment) => equipment.description);
      const mergedArray = [...values, ...equipmentArray];
      setSuggestions(mergedArray);
      await valueStore.set(`equipment`, values);
    });
  };

  const filteredSuggestions = useMemo(
    () =>
      suggestions.filter((suggestion) => !initialEquipment.map((equipment) => equipment.description.toLowerCase()).includes(suggestion.toLowerCase())),
    [initialEquipment, suggestions],
  );

  useEffect(async () => {
    if (isOnline) {
      sync();
    } else {
      await valueStore.getArray(`equipment`);
    }
  }, []);

  return (
    <MultiField
      name="equipment"
      values={initialEquipment}
      required={false}
      push={push}
      insert={insert}
      label="What equipment will be used?"
      placeholder="Add new Equipment"
      suggestions={filteredSuggestions}
    />
  );
};

export default EquipmentList;
