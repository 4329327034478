import cls from 'classnames';
import OptionalLabel from 'Components/Forms/OptionalLabel';
import { Field } from 'formik';
import React from 'react';

const TextareaField = ({ name, label = null, type = 'text', hint, ...props }) => (
  <div>
    <OptionalLabel name={name} label={label} />
    <span className="text-xs text-gray-600">{hint}</span>
    <div className="mt-1">
      <Field
        className={cls('form-input block w-full rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5', props.className)}
        as="textarea"
        name={name}
        {...props}
      />
    </div>
  </div>
);

export default TextareaField;
