import { useContext, useEffect } from 'react';
import { LayoutContext } from 'Support/Contexts/LayoutContext';

const usePageTitle = (title, deps = []) => {
  const { setPageTitle } = useContext(LayoutContext);

  useEffect(() => setPageTitle(typeof title === 'function' ? title() : title), [title, ...deps]);
};

export default usePageTitle;
