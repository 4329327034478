import React, { createContext, useCallback, useMemo, useState } from 'react';
import api from 'Services/api';
import valueStore from 'Services/valueStore';

export const JobContext = createContext({
  jobs: [],
  currentJob: {},
  updateCurrentJobId: () => undefined,
  refresh: () => undefined,
});

export const JobContextProvider = ({ children }) => {
  const [jobs, setJobs] = useState([]);
  const [currentJobId, setCurrentJobId] = useState(null);
  const refreshData = async () => {
    const newJobs = (await api.syncJobs().catch(() => valueStore.getArray('jobs'))) || [];
    await valueStore.set(`jobs`, newJobs);
    setJobs(newJobs);
  };

  const currentJob = useMemo(() => {
    return jobs.find((job) => {
      if (job.id) {
        return job.id === parseInt(currentJobId);
      }
      return job.temp_id === currentJobId;
    });
  }, [jobs, currentJobId]);

  const updateCurrentJobId = useCallback((id) => setCurrentJobId(id), []);

  return <JobContext.Provider value={{ jobs, currentJob, updateCurrentJobId, refresh: refreshData }}>{children}</JobContext.Provider>;
};
