import { mdiCalendar, mdiMail, mdiPhone } from '@mdi/js';
import Icon from '@mdi/react';
import ListItem from 'Components/ListItem';
import ListItemWrapper from 'Components/ListItemWrapper';
import { format, parseJSON } from 'date-fns';
import Tabs from 'Pages/Jobs/Tabs';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { JobContext } from 'Support/Contexts/JobContext';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';

const VisitorLogs = () => {
  const { id } = useParams();
  const { currentJob, updateCurrentJobId, refresh } = useContext(JobContext);

  useEffect(() => {
    updateCurrentJobId(id);
    refresh();
  }, [id]);

  usePageTitle(currentJob?.name);

  return (
    <>
      <Tabs job={currentJob} active="visitor_logs" />
      <div className="relative bg-white p-4">
        <ListItemWrapper>
          {currentJob?.view_logs?.map((visitorLog) => (
            <ListItem
              key={visitorLog.id || visitorLog.name}
              mainTitle={
                <div className="flex items-center gap-2">
                  <div className="flex flex-col">
                    {visitorLog.name}
                    {visitorLog.phone && (
                      <div className="flex space-x-1 text-gray-600">
                        <Icon path={mdiPhone} size={0.6} />
                        <p>{visitorLog.phone}</p>
                      </div>
                    )}
                    {visitorLog.email && (
                      <div className="flex space-x-1 text-gray-600">
                        <Icon path={mdiMail} size={0.6} />
                        <p>{visitorLog.email}</p>
                      </div>
                    )}
                    <div className="flex space-x-1 text-gray-600">
                      <Icon path={mdiCalendar} size={0.6} />
                      <p>
                        {visitorLog.created_at === 'now' || !visitorLog.created_at
                          ? format(Date.now(), 'yyyy/MM/dd hh:mm aaa')
                          : format(parseJSON(visitorLog.created_at), 'yyyy/MM/dd hh:mm aaa')}
                      </p>
                    </div>
                  </div>
                </div>
              }
              as={Link}
              to={visitorLog.id ? route('visitor-log.view', { jobId: currentJob.id, id: visitorLog.id }) : '#'}
            />
          ))}
        </ListItemWrapper>
      </div>
    </>
  );
};

export default VisitorLogs;
