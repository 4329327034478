import { mdiAlert, mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import PopUp from 'Components/PopUp';
import React, { useState } from 'react';

export const AreYouSureYouDiscardDataPopUp = ({ isOpen, setOpen }) => {
  const [disabled, setDisabled] = useState(false);
  return (
    <PopUp setOpen={setOpen} isOpen={isOpen} canClose={false} disabled={disabled}>
      <div className="ml-3 mt-4 flex">
        <Icon path={mdiAlert} size={1} className="text-red-500" />
        <h4 className="w-full px-3 pb-5 font-semibold tracking-wider text-gray-900" id="projects-headline">
          Warning - Are you sure you want to discard data?
        </h4>
      </div>
      <hr />
      <div className="flex h-full flex-col justify-between px-3">
        <div className="flex flex-col divide-y divide-gray-200 pb-4 pt-3">
          <p>You will lose all unsynced data on this device.</p>
        </div>
        <div className="mb-4 flex justify-between space-x-5">
          <SecondaryButton
            type="button"
            disabled={disabled}
            onClick={async () => {
              setOpen(false, null);
            }}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton
            type="button"
            disabled={disabled}
            onClick={() => {
              setDisabled(true);
              setOpen(false, true);
            }}
            className="h-full py-2"
          >
            {disabled && <Icon path={mdiLoading} size={1} className="mr-2 animate-spin text-white" />}
            Yes, Discard data
          </PrimaryButton>
        </div>
      </div>
    </PopUp>
  );
};
