import { mdiArrowLeft, mdiFormTextbox, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import cls from 'classnames';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import ListItem from 'Components/ListItem.jsx';
import ListItemWrapper from 'Components/ListItemWrapper.jsx';
import { DashboardCard } from 'Pages/Dashboard.jsx';
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';

const splitForms = (forms) => {
  return forms.reduce(
    (form, item) => {
      if (item.special_use) {
        form.specialForms.push(item);
      } else {
        form.otherForms.push(item);
      }
      return form;
    },
    { specialForms: [], otherForms: [] },
  );
};

const SelectFormTemplate = () => {
  const isOnline = useOnlineStatus();
  const [otherForms, setOtherForms] = useState([]);
  const [specialForms, setSpecialForms] = useState([]);
  const [searchedForms, setSearchedForms] = useState([]);
  const [syncing, setSyncing] = useState(false);
  const [search, setSearch] = useState('');

  const sync = async () => {
    if (!syncing) {
      setSyncing(true);
      const formsData = await api.syncForms();
      const { specialForms, otherForms } = splitForms(formsData);

      setOtherForms(otherForms);
      setSearchedForms(otherForms);
      setSpecialForms(specialForms);

      setSyncing(false);
    }
  };

  useEffect(() => {
    if (search) {
      setSearchedForms(otherForms.filter((form) => form.name?.toLowerCase().includes(search.toLowerCase())));
    } else {
      setSearchedForms(otherForms);
    }
  }, [search]);

  useEffect(async () => {
    if (isOnline) {
      await sync();
    }
  }, []);

  useEffect(async () => {
    const { specialForms, otherForms } = splitForms((await valueStore.getArray(`forms`)) || []);
    setSpecialForms(specialForms);
    setOtherForms(otherForms);
  }, []);

  usePageTitle(`Select a form template`);

  return (
    <div className="m-4">
      <SecondaryButton as={Link} to={route('reports')} className="mb-12 w-full py-2">
        <Icon path={mdiArrowLeft} size={1} className="mr-1" /> View Forms
      </SecondaryButton>

      <div className={cls('space-y-4')}>
        {specialForms.map((form, index) => (
          <DashboardCard key={index}>
            <div className="flex w-full items-center justify-start space-x-2 p-4">
              <div className="rounded-xl bg-primary p-4 text-white">
                <Icon path={mdiFormTextbox} size={1} />
              </div>
              <div className="flex w-full items-center justify-between">
                <div className="font-semibold">
                  <h3 className="text-xl font-bold text-gray-900">{form.name}</h3>
                </div>
              </div>
            </div>
            <div className="relative">
              <Link
                to={route('reports.create', { id: form.id })}
                as="button"
                className="flex w-full items-center rounded-b-xl bg-gray-100 px-4 py-2 text-primary"
              >
                <Icon path={mdiPlus} size={0.75} />
                <p className="text-primary">Create new</p>
              </Link>
            </div>
          </DashboardCard>
        ))}
        <ListItemWrapper searchName="Form Templates" setSearch={setSearch} searchDisabled={syncing} className="absolute w-full pb-20">
          {searchedForms?.map((form, index) => (
            <ListItem
              key={form.id}
              mainTitle={<div className="flex items-center gap-2">{form.name}</div>}
              as={Link}
              to={route('reports.create', { id: form.id })}
            />
          ))}
        </ListItemWrapper>
      </div>
    </div>
  );
};

export default SelectFormTemplate;
