import { mdiCloudOffOutline } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import React, { useEffect, useState } from 'react';
import api from 'Services/api';

const ImageDisplay = ({ media, uuid, className, induction }) => {
  const [image, setImage] = useState('');

  useEffect(() => {
    api.getImageTemporaryURL(uuid ? uuid : media.uuid, induction).then(setImage);
  }, []);

  return image ? (
    <img className={className} src={image} alt={image?.name} />
  ) : (
    <div className={cls('rounded-lg border border-gray-100 bg-white p-4', className)}>
      <Icon path={mdiCloudOffOutline} size={1} className="m-auto animate-pulse text-gray-300" />
    </div>
  );
};

export default ImageDisplay;
