import { mdiArrowLeft, mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import cls from 'classnames';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import CameraField from 'Components/CameraField';
import CheckboxGroupField from 'Components/Forms/CheckboxGroupField';
import FieldWrapper from 'Components/Forms/FieldWrapper';
import FormWrapper from 'Components/Forms/FormWrapper';
import SwitchField from 'Components/Forms/SwitchField';
import TextareaField from 'Components/Forms/TextareaField';
import TextField from 'Components/Forms/TextField';
import SyncSpinner from 'Components/SyncSpinner';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import AttendeesList from 'Pages/Jobs/AttendeesList';
import EquipmentList from 'Pages/Jobs/EquipmentList';
import HazardList from 'Pages/Jobs/HazardList';
import PPEList from 'Pages/Jobs/PPEList';
import StepList from 'Pages/Jobs/StepList';
import TrainingList from 'Pages/Jobs/TrainingList';
import { useEffect, useState } from 'react';
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import valueStore from 'Services/valueStore';
import useFormikSubmit from 'Support/hooks/useFormikSubmit';
import useOrganization from 'Support/hooks/useOrganization';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

const ErrorMessageCustom = ({ currentSectionErrors }) => {
  //errors {ppe: blab } current ['ppe', 'training']
  if (currentSectionErrors.length > 0) {
    return (
      <p className="font-xs border-0 pt-2 text-red-500">
        {currentSectionErrors.map((error, index) => {
          if (index !== currentSectionErrors.length - 1) {
            return error + ', ';
          } else {
            return error;
          }
        })}
      </p>
    );
  } else {
    return null;
  }
};

const currentSectionErrors = (errors, currentSection) => {
  return currentSection.map((error) => errors[error]).filter((x) => x !== undefined);
};

const EditTaskAnalysis = () => {
  const { id } = useParams();
  const history = useHistory();
  const [currentTaskAnalysis, setCurrentTaskAnalysis] = useState();
  const [taskAnalysis, setTaskAnalysis] = useState([]);
  const [companyChecks, setCompanyChecks] = useState([]);
  const [currentImages, setCurrentImages] = useState([]);
  const [formikProps, setFormikProps] = useState();
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const isOnline = useOnlineStatus();

  useEffect(() => {
    Promise.all([valueStore.getArray(`taskAnalysis`), valueStore.getArray(`companyChecks`)]).then(([taskAnalysis, companyChecks]) => {
      setTaskAnalysis(taskAnalysis || []);
      setCompanyChecks(companyChecks || []);
    });
  }, []);

  usePageTitle(`Add Task Analysis (from copy)`);

  const validationSchema = Yup.object().shape({
    attendees: Yup.array().test('attendees', 'Please enter at least one attendee', (items) => items.filter(Boolean).length),

    activity: Yup.string().required('Please provide an activity').max(500, 'Activity must be at most 500 characters'),

    location: Yup.string().required('Please provide a location'),

    emergency_location: Yup.string().required('Please provide an emergency location'),

    checks: Yup.array().test('checks', 'Please perform all the checks', (items) => items?.length === companyChecks?.length),

    hazards: Yup.array()
      .test('hazards', 'Please enter at least one Hazard', (items) => items.some((item) => item.description?.length))
      .of(
        Yup.object()
          .test('hazards', 'Please enter Initial risk rating', (value) => {
            if (value?.description !== undefined) {
              return value.risk_rating || value.initial_risk_rating;
            }
            return true;
          })
          .test('hazards', 'Please enter Residual risk rating', (value) => {
            if (value?.description !== undefined) {
              return value.residual_risk_rating !== undefined;
            }
            return true;
          }),
      ),
    ppe: Yup.array().test('ppe', 'Please enter at least one PPE item', (items) => items.some((item) => item.description?.length)),

    equipment: Yup.array().test('equipment', 'Please enter at least one equipment item', (items) => items.some((item) => item.description?.length)),

    training: Yup.array().test('training', 'Please enter at least one training item', (items) => items.some((item) => item.description?.length)),

    steps: Yup.array().test('steps', 'Please enter at least one step', (items) => items.some((item) => item.description?.length)),

    site_good: Yup.bool().oneOf([true], 'Please confirm the site status is "good to go"'),

    additional_comments: Yup.string(),
  });

  const onSubmit = useFormikSubmit(async (values) => {
    const { organization_id } = useOrganization();
    const data = {
      ...currentTaskAnalysis,
      activity: values.activity,
      created_at: 'now',
      emergency_location: values.emergency_location,
      equipment: values.equipment || [],
      hazards: values.hazards || [],
      training: values.training || [],
      attendees: values.attendees || [],
      location: values.location || '',
      ppe: values.ppe || [],
      site_good: false,
      steps: values.steps || [],
      additional_comments: values.additional_comments || '',
      files: currentImages || [],
      checks: values.checks || [],
      updated: true,
    };

    delete data.incomplete;

    let tasksForUpload = taskAnalysis.map((ta) => {
      if (parseInt(id) === ta.id || id === ta.temp_id) {
        return data;
      }
      return ta;
    });
    if (!isOnline) {
      data['temp_id'] = uuidv4();
    }
    data['organization_id'] = organization_id;

    setTaskAnalysis(tasksForUpload);
    await valueStore.set(`taskAnalysis`, tasksForUpload);

    history.push(route('job', { id: data.job_id }));
  });

  useEffect(async () => {
    //in the event you are able to get the actual ID
    setCurrentTaskAnalysis(
      taskAnalysis?.find((ta) => {
        if (ta.id) {
          return ta.id === parseInt(id);
        }
        return ta.temp_id === id;
      }),
    );
  }, [JSON.stringify(taskAnalysis)]);

  useEffect(async () => {
    if ((currentTaskAnalysis?.id || currentTaskAnalysis?.temp_id) && !formikProps?.initialValues) {
      setFormikProps({
        initialValues: {
          activity: currentTaskAnalysis?.activity,
          location: currentTaskAnalysis?.location,
          emergency_location: currentTaskAnalysis?.emergency_location,
          training: currentTaskAnalysis?.training || [],
          attendees: currentTaskAnalysis?.attendees || [],
          checks: [],
          hazards:
            currentTaskAnalysis?.hazards?.map((hazard) => ({
              ...hazard,
              initial_risk_rating: hazard.initial_risk_rating || hazard.risk_rating,
              residual_risk_rating: hazard.residual_risk_rating,
              hazard_controls: hazard.hazard_controls || [],
            })) || [],
          files: [],
          ppe: currentTaskAnalysis?.ppe || [],
          equipment: currentTaskAnalysis?.equipment || [],
          steps: currentTaskAnalysis?.steps || [],
          site_good: currentTaskAnalysis?.site_good,
          additional_comments: currentTaskAnalysis?.additional_comments || '',
        },
      });
      setLoading(false);
    }
  }, [JSON.stringify(currentTaskAnalysis)]);

  return (
    <div className="m-4">
      <SyncSpinner loading={loading} />

      {currentTaskAnalysis?.job_id && (
        <SecondaryButton as={Link} to={route('job', { id: currentTaskAnalysis?.job_id })} className="mb-12 w-full py-2">
          <Icon path={mdiArrowLeft} size={1} className="mr-1" /> Cancel/Back to job
        </SecondaryButton>
      )}

      {!loading && (
        <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({ values, setFieldValue, errors }) => {
            useEffect(() => {
              if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(async ({ coords: position }) => {
                  if (!position.latitude || !position.longitude || typeof geocodeByLatLng !== 'function') return;
                  const coordinates = `${position.latitude},${position.longitude}`;

                  geocodeByLatLng({ lat: position.latitude, lng: position.longitude })
                    .then((results) => {
                      setFieldValue('location', results[0]?.formatted_address || coordinates);
                    })
                    .catch(() => setFieldValue('location', coordinates));
                });
              }
            }, []);

            return (
              <Form>
                <FormWrapper className={cls('-m-4 rounded-lg bg-white p-4 shadow-md', page !== 1 && 'hidden')}>
                  <FieldWrapper>
                    <FieldArray name="attendees">{(props) => <AttendeesList attendees={values.attendees || []} {...props} />}</FieldArray>
                    <ErrorMessage name="attendees" component="div" className="font-content text-xs italic text-red-500" />
                  </FieldWrapper>

                  <FieldWrapper>
                    <TextField name="activity" label="What are you doing?" autoFocus />
                  </FieldWrapper>

                  <FieldWrapper>
                    <FieldArray name="steps">{(props) => <StepList steps={values.steps || []} {...props} />}</FieldArray>
                    <ErrorMessage name="steps" component="div" className="font-content text-xs italic text-red-500" />
                  </FieldWrapper>

                  <FieldWrapper>
                    <TextField name="location" />
                  </FieldWrapper>

                  <FieldWrapper>
                    <TextField name="emergency_location" />
                  </FieldWrapper>
                  <ErrorMessageCustom
                    currentSectionErrors={currentSectionErrors(errors, ['activity', 'attendees', 'steps', 'emergency_location', 'location'])}
                  />
                  <FieldWrapper className="mt-6 grid grid-cols-2 gap-4">
                    <div />
                    <PrimaryButton
                      type="button"
                      disabled={currentSectionErrors(errors, ['activity', 'attendees', 'steps', 'emergency_location', 'location']).length > 0}
                      className="w-full"
                      onClick={() => setPage(2)}
                    >
                      Next <Icon path={mdiChevronRight} size={1} />
                    </PrimaryButton>
                  </FieldWrapper>
                </FormWrapper>

                <FormWrapper className={cls('-m-4 rounded-lg bg-white p-4 shadow-md', page !== 2 && 'hidden')}>
                  <FieldWrapper>
                    <FieldArray name="training">{(props) => <TrainingList required={true} training={values.training || []} {...props} />}</FieldArray>
                    <ErrorMessage name="training" component="div" className="font-content text-xs italic text-red-500" />
                  </FieldWrapper>

                  <FieldWrapper>
                    <FieldArray name="ppe">{({ ...props }) => <PPEList required={true} ppe={values.ppe || []} {...props} />}</FieldArray>
                    <ErrorMessage name="ppe" component="div" className="font-content text-xs italic text-red-500" />
                  </FieldWrapper>

                  <FieldWrapper>
                    <FieldArray name="equipment">
                      {({ ...props }) => <EquipmentList required={true} equipment={values.equipment || []} {...props} />}
                    </FieldArray>
                    <ErrorMessage name="equipment" component="div" className="font-content text-xs italic text-red-500" />
                  </FieldWrapper>
                  <ErrorMessageCustom currentSectionErrors={currentSectionErrors(errors, ['training', 'ppe', 'equipment'])} />
                  <FieldWrapper className="mt-6 grid grid-cols-2 gap-4">
                    <SecondaryButton as="div" className="w-full" onClick={() => setPage(1)}>
                      <Icon path={mdiChevronLeft} size={1} /> Prev
                    </SecondaryButton>
                    <PrimaryButton
                      type="button"
                      disabled={currentSectionErrors(errors, ['training', 'ppe', 'equipment']).length > 0}
                      className="w-full"
                      onClick={() => setPage(3)}
                    >
                      Next <Icon path={mdiChevronRight} size={1} />
                    </PrimaryButton>
                  </FieldWrapper>
                </FormWrapper>

                <FormWrapper className={cls('-m-4 rounded-lg bg-white p-4 shadow-md', page !== 3 && 'hidden')}>
                  <FieldWrapper>
                    <FieldArray name="hazards">
                      {({ push }) => <HazardList hazards={values.hazards || []} push={push} setFieldValue={setFieldValue} />}
                    </FieldArray>
                    <ErrorMessage name="hazards" component="div" className="font-content text-xs italic text-red-500" />
                  </FieldWrapper>
                  <ErrorMessageCustom currentSectionErrors={currentSectionErrors(errors, ['hazards'])} />
                  <FieldWrapper className="mt-6 grid grid-cols-2 gap-4">
                    <SecondaryButton as="div" className="w-full" onClick={() => setPage(2)}>
                      <Icon path={mdiChevronLeft} size={1} /> Prev
                    </SecondaryButton>
                    <PrimaryButton
                      type="button"
                      disabled={currentSectionErrors(errors, ['hazards']).length > 0}
                      className="w-full"
                      onClick={() => setPage(4)}
                    >
                      Next <Icon path={mdiChevronRight} size={1} />
                    </PrimaryButton>
                  </FieldWrapper>
                </FormWrapper>

                <FormWrapper className={cls('-m-4 rounded-lg bg-white p-4 shadow-md', page !== 4 && 'hidden')}>
                  <FieldWrapper>
                    <CheckboxGroupField
                      name="checks"
                      options={companyChecks}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.caption}
                    />
                  </FieldWrapper>

                  <FieldWrapper>
                    <SwitchField name="site_good" label="Site status" yesText="Good to go" noText="Not good to go" />
                  </FieldWrapper>

                  <FieldWrapper>
                    <TextareaField
                      name="additional_comments"
                      label="Additional Comments/Toolbox Talk"
                      className="h-20 w-full"
                      hint="(if an action is raised please create and assign a task)"
                    />
                  </FieldWrapper>
                  <FieldWrapper className="mt-6 grid grid-cols-2 gap-4">
                    <SecondaryButton as="div" className="w-full" onClick={() => setPage(3)}>
                      <Icon path={mdiChevronLeft} size={1} /> Prev
                    </SecondaryButton>
                    <PrimaryButton
                      type="button"
                      className="w-full"
                      disabled={currentSectionErrors(errors, ['checks']).length > 0}
                      onClick={() => setPage(5)}
                    >
                      Next <Icon path={mdiChevronRight} size={1} />
                    </PrimaryButton>
                  </FieldWrapper>
                </FormWrapper>
                <FormWrapper className={cls('-m-4 rounded-lg bg-white p-4 shadow-md', page !== 5 && 'hidden')}>
                  <p>Current Uploaded Images: {currentTaskAnalysis?.media_count}</p>
                  <p className="mb-2 rounded bg-blue-100 p-4 text-sm text-blue-700">
                    Please take a photo of all attendees to confirm and acknowledge attendance.
                  </p>
                  <CameraField uploadedImages={currentImages} setUploadedImages={setCurrentImages} />
                  {!values.site_good && (
                    <FieldWrapper>
                      <SwitchField name="site_good" label="Site status" yesText="Good to go" noText="Not good to go" />
                    </FieldWrapper>
                  )}
                  <p className="font-xs border-0 pt-2 text-red-500">{Object.values(errors).map((error) => error)}</p>
                  <FieldWrapper className="mt-6 grid grid-cols-2 gap-4">
                    <SecondaryButton as="div" className="w-full" onClick={() => setPage(4)}>
                      <Icon path={mdiChevronLeft} size={1} /> Prev
                    </SecondaryButton>
                    <PrimaryButton type="submit" className="w-full" disabled={Object.values(errors).length}>
                      Submit <Icon path={mdiChevronRight} size={1} />
                    </PrimaryButton>
                  </FieldWrapper>
                </FormWrapper>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default EditTaskAnalysis;
