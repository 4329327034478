import { mdiArrowLeft, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import FieldWrapper from 'Components/Forms/FieldWrapper';
import FormWrapper from 'Components/Forms/FormWrapper';
import TextareaField from 'Components/Forms/TextareaField';
import SyncSpinner from 'Components/SyncSpinner';
import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import useFormikSubmit from 'Support/hooks/useFormikSubmit';
import usePageTitle from 'Support/hooks/usePageTitle';
import useUser from 'Support/hooks/useUser';
import route from 'Support/route';
import * as Yup from 'yup';

const Comments = () => {
  const previousMessageBox = useRef();
  const { id } = useParams();
  const isOnline = useOnlineStatus();
  const [currentComments, setCurrentComments] = useState([]);
  const [currentTask, setCurrentTask] = useState();
  const [tasks, setTasks] = useState([]);
  const [formikProps, setFormikProps] = useState();
  const [validationSchema, setValidationSchema] = useState({});
  const [loading, setLoading] = useState(true);
  const currentUser = useUser();

  const scrollToBottom = () => {
    previousMessageBox.current?.scrollTo(0, previousMessageBox.current?.scrollHeight);
  };

  useEffect(async () => {
    setTasks((await valueStore.getArray(`tasks`)) || []);
  }, []);

  usePageTitle(`Task`);

  const onSubmit = useFormikSubmit(async (values) => {
    if (values.comment) {
      let newComments = {
        ...currentTask,
        comments: [
          ...currentComments,
          {
            newComment: values.comment,
          },
        ],
        updated: true,
      };
      newComments['status'] = newComments['status'].value;
      let tasksForUpload = tasks.map((task) => {
        //temp id is not needed because we do not currently
        // allow to edit this section if it was just created offline
        if (parseInt(id) === task.id) {
          return newComments;
        }
        return task;
      });

      setTasks(tasksForUpload);
      await valueStore.set(`tasks`, tasksForUpload);

      if (isOnline) {
        await api.syncTasks();
      }
    }
  });

  useEffect(async () => {
    let tempCurrentTask = tasks?.find((task) => task.id === parseInt(id));
    if (tempCurrentTask) {
      setCurrentTask(tempCurrentTask);
      setCurrentComments(tasks?.find((task) => task.id === parseInt(id)).comments);
      scrollToBottom();
    }
  }, [JSON.stringify(tasks), JSON.stringify(currentComments)]);

  useEffect(() => {
    if (currentTask) {
      setFormikProps({
        initialValues: {
          comment: '',
        },
      });
      setValidationSchema(
        Yup.object().shape({
          comment: Yup.string(),
        }),
      );
      setLoading(false);
    }
  }, [JSON.stringify(currentTask)]);

  return (
    <div className="m-4">
      <SyncSpinner loading={loading} />

      <SecondaryButton as={Link} to={route('tasks.edit', { id: id })} className="mb-12 w-full py-2">
        <Icon path={mdiArrowLeft} size={1} className="mr-1" /> Back
      </SecondaryButton>

      {!loading && (
        <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({ errors }) => (
            <Form>
              <FormWrapper className="flex max-h-screen flex-col rounded-lg bg-white shadow-md">
                <div ref={previousMessageBox} className="grow overflow-y-auto pt-6">
                  {currentComments?.map((comment) => (
                    <p key={comment.id ? comment.id : comment.newComment} className="mb-6 border-b border-gray-400 pb-2">
                      <span className="font-bold">{id ? currentUser.name : comment.user ? comment.user?.name : 'User'}:</span>{' '}
                      {comment.comment ? comment.comment : comment.newComment}
                    </p>
                  ))}
                </div>
                <FieldWrapper>
                  <TextareaField name="comment" />
                </FieldWrapper>
                <FieldWrapper className="mt-6 grid grid-cols-2 gap-4">
                  <PrimaryButton type="submit" className="w-full" disabled={Object.values(errors).length}>
                    Submit <Icon path={mdiChevronRight} size={1} />
                  </PrimaryButton>
                </FieldWrapper>
              </FormWrapper>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default Comments;
