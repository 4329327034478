import cls from 'classnames';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import useFormikSubmit from 'Support/hooks/useFormikSubmit';
import route from 'Support/route';
import * as Yup from 'yup';

const ResetPasswordForm = (props) => {
  const history = useHistory();

  const formikProps = {
    initialValues: {
      password: '',
    },
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Please provide a password'),
  });

  const onSubmit = useFormikSubmit(async (values) => {
    const email = await valueStore.get(`reset-email`);
    const code = await valueStore.get(`reset-code`);
    const verified = await api.setPassword(email, code, values.password);
    if (verified) {
      history.push(route('login'));
    }
  });

  return (
    <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
      <Form {...props}>
        <div className="mb-6">Enter your new password.</div>

        <div className="mt-6">
          <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
            Password
          </label>
          <div className="mt-1">
            <Field
              className={cls(
                'block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm',
                'focus:shadow-outline-blue transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5',
              )}
              type="password"
              name="password"
            />
            <ErrorMessage name="password" component="div" className="font-content text-xs italic text-red-500" />
          </div>
        </div>

        <div className="mt-6">
          <span className="block w-full">
            <PrimaryButton type="submit" className="w-full">
              Sign in
            </PrimaryButton>
            <Link to={route('login')} className="mt-6 block text-center text-sm text-primary">
              Login
            </Link>
          </span>
        </div>
      </Form>
    </Formik>
  );
};

export default ResetPasswordForm;
