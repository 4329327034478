import ActionField from 'Pages/Reports/ActionField';
import PreviousAction from 'Pages/Reports/PreviousAction';
import React, { useEffect } from 'react';
import useDebounce from 'Support/hooks/useDebounce';
import { v4 as uuidv4 } from 'uuid';

const ActionList = ({ setFieldValue, actions, push, previousActions }) => {
  const debouncedActions = useDebounce(actions, 100);

  useEffect(() => {
    const hasEmptyItem = debouncedActions.some((event) => !event.message?.length);
    if (!hasEmptyItem) {
      push({
        assigned_id: 0,
        temp_id: uuidv4(),
        message: '',
        due_in: 0,
        is_corrective_action: true,
      });
    }
  }, [JSON.stringify(debouncedActions)]);

  return (
    <div className="space-y-4">
      {previousActions?.map((action, index) => (
        <PreviousAction className="rounded border p-4 shadow" key={index} action={action} />
      ))}
      {actions.map((action, index) => (
        <div className="rounded border p-4 shadow" key={index}>
          <label className="block text-sm leading-5 text-gray-800">Action {index + 1}</label>
          <ActionField actions={actions} index={index} setFieldValue={setFieldValue} />
        </div>
      ))}
    </div>
  );
};

export default ActionList;
