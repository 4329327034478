import { mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import { AreYouSureYouDiscardDataPopUp } from 'Components/AreYouSureYouDiscardDataPopUp';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import { FailedSyncPopUp, removeDataFromLocalStorage } from 'Components/FailedSyncPopUp';
import { PreventSuperAdminSignIn } from 'Components/PreventSuperAdminSignIn';
import { hasDataToSync, syncDataFromOtherOrganization, UnSyncedDataPopUp } from 'Components/UnSyncedDataPopUp';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import auth from 'Services/auth';
import { syncAllData } from 'Services/syncAllData';
import valueStore from 'Services/valueStore';
import useFormikSubmit from 'Support/hooks/useFormikSubmit';
import route from 'Support/route';
import * as Yup from 'yup';

const LoginForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [unSyncedDataOpen, setUnSyncedDataOpen] = useState(false);
  const [failedSyncOpen, setFailedSyncOpen] = useState(false);
  const [areYouSureOpen, setAreYouSureOpen] = useState(false);
  const [superAdminOpen, setSuperAdminOpen] = useState(false);
  const [logout, setLogout] = useState(false);
  const [discardData, setDiscardData] = useState(null);
  const [unSyncDataDisabled, setUnSyncDataDisabled] = useState(false);

  useEffect(() => {
    async function authLogout() {
      if (logout) {
        window.location.href = route('login');
      }
    }
    authLogout();
  }, [logout]);

  const formikProps = {
    initialValues: {
      email: '',
      password: '',
      remember: false,
    },
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Please provide your e-mail address').email('Please provide a valid e-email address'),

    password: Yup.string().required('Please provide a password'),
  });

  useEffect(async () => {
    if (discardData !== null) {
      if (!discardData) {
        if (await syncDataFromOtherOrganization()) {
          setFailedSyncOpen(true);
        } else {
          await afterEverything();
        }
      } else {
        setAreYouSureOpen(true);
      }
    }
  }, [discardData]);

  const afterEverything = async () => {
    await Promise.all([
      syncAllData(),
      auth.setPreviousOrganizationName(auth.getOrganizationName()),
      valueStore.set('previous_user', auth.getUser().id),
      valueStore.set('previous_organization', auth.getOrganization()),
      valueStore.set('previous_organization_name', auth.getOrganizationName()),
    ]);
    history.push(route('dashboard'));
  };

  const onSubmit = useFormikSubmit(async (values) => {
    setLoading(true);

    await auth.authenticate(values);

    if (!auth.getPermissions().use_pwa) {
      await auth.logout();
      setSuperAdminOpen(true);
    } else {
      if (await hasDataToSync()) {
        setUnSyncedDataOpen(true);
      } else {
        setDiscardData(false);
      }
    }
  });

  const endAreYouSure = async (areYouSureOpen, newDiscardData) => {
    setAreYouSureOpen(areYouSureOpen);
    setDiscardData(newDiscardData);
    if (newDiscardData) {
      await removeDataFromLocalStorage();
      await afterEverything();
    } else {
      setUnSyncedDataOpen(true);
      setUnSyncDataDisabled(false);
    }
  };

  return (
    <>
      <PreventSuperAdminSignIn isOpen={superAdminOpen} setOpen={setSuperAdminOpen} setValue={setLogout} />
      <UnSyncedDataPopUp
        isOpen={unSyncedDataOpen}
        setOpen={setUnSyncedDataOpen}
        setValue={setDiscardData}
        disabled={unSyncDataDisabled}
        setDisabled={setUnSyncDataDisabled}
      />
      <FailedSyncPopUp isOpen={failedSyncOpen} setOpen={setFailedSyncOpen} onSubmit={afterEverything} />
      <AreYouSureYouDiscardDataPopUp isOpen={areYouSureOpen} setOpen={endAreYouSure} />
      <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
        <Form {...props}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
              Email address
            </label>
            <div className="mt-1">
              <Field
                className={cls(
                  'block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm',
                  'focus:shadow-outline-blue transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5',
                )}
                type="email"
                name="email"
                placeholder="jane.doe@email.com"
              />
              <ErrorMessage name="email" component="div" className="font-content text-xs italic text-red-500" />
            </div>
          </div>

          <div className="mt-6">
            <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
              Password
            </label>
            <div className="mt-1">
              <Field
                className={cls(
                  'block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm',
                  'focus:shadow-outline-blue transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5',
                )}
                type="password"
                name="password"
              />
              <ErrorMessage name="password" component="div" className="font-content text-xs italic text-red-500" />
            </div>
          </div>

          <div className="mt-6">
            <span className="block w-full">
              <PrimaryButton type="submit" className="w-full" disabled={loading}>
                {loading && <Icon path={mdiLoading} size={1} className="mr-2 animate-spin" />}
                Sign in
              </PrimaryButton>
              <Link to={route('forgot.password')} className="mt-6 block text-center text-sm text-primary">
                Forgot password
              </Link>
            </span>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default LoginForm;
