import cls from 'classnames';
import React, { forwardRef } from 'react';

const SecondaryButton = ({ children, className = '', as = 'button', ...otherProps }, ref) => {
  const Element = as;

  return (
    <Element
      ref={ref}
      className={cls(
        'border-grey-100 inline-flex items-center justify-center border bg-white text-black transition duration-150 ease-in-out hover:text-primary',
        'rounded px-6 text-base',
        'hover:opacity-90 focus:outline-none',
        'disabled:cursor-default disabled:opacity-50',
        className,
      )}
      {...otherProps}
    >
      {children}
    </Element>
  );
};

export default forwardRef(SecondaryButton);
