import React from 'react';
import { MagicSpinner } from 'react-spinners-kit';
import theme from 'tailwind-theme';

const Initialising = () => (
  <main className="relative flex min-h-screen flex-col items-center justify-center bg-gray-100">
    <div className="relative z-10">
      <img src="/icon/logo-dark.png/1" alt="Logo" className="mx-auto mb-3 w-64 max-w-screen-3/4" />
      <h1 className="mb-16 text-center text-2xl text-gray-900">We're getting things ready</h1>
    </div>
    <div className="absolute overflow-hidden opacity-25">
      <MagicSpinner size={100} sizeUnit="vh" color={theme.backgroundColor.orange[500]} />
    </div>
  </main>
);

export default Initialising;
