import ForgotPasswordForm from 'Forms/Auth/ForgotPasswordForm';
import React from 'react';

const ForgotPassword = () => (
  <div className="flex h-screen items-center justify-center">
    <div className="mx-auto w-full max-w-sm px-4">
      <div className="flex justify-center">
        <img className="max-w-full" src="/icon/logo-dark.png/1" alt="Logo" />
      </div>

      <div className="mt-6">
        <ForgotPasswordForm className="block max-w-full" />
      </div>
    </div>
  </div>
);

export default ForgotPassword;
