import { mdiPlus, mdiSync } from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import cls from 'classnames';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import ListItem from 'Components/ListItem';
import ListItemWrapper from 'Components/ListItemWrapper';
import SyncSpinner from 'Components/SyncSpinner';
import WarningOfDataLimit from 'Components/WarningOfDataLimit';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';

const Jobs = () => {
  usePageTitle('Jobs');
  const isOnline = useOnlineStatus();
  const [jobs, setJobs] = useState([]);
  const [searchedJobs, setSearchedJobs] = useState([]);
  const [syncing, setSyncing] = useState(false);
  const [search, setSearch] = useState('');

  const sync = async () => {
    if (isOnline && !syncing) {
      setSyncing(true);
      const queriedJobs = await api.syncJobs();
      setJobs(queriedJobs);
      setSearchedJobs(queriedJobs);
    } else {
      const queriedJobs = await valueStore.getArray('jobs');
      setJobs(queriedJobs);
      setSearchedJobs(queriedJobs);
    }

    setSyncing(false);
  };

  useEffect(() => {
    if (search) {
      setSearchedJobs(
        jobs.filter(
          (job) =>
            job.name?.toLowerCase().includes(search.toLowerCase()) ||
            job.foreman_person?.toLowerCase().includes(search.toLowerCase()) ||
            job.location?.toLowerCase().includes(search.toLowerCase()) ||
            job.supervisor?.toLowerCase().includes(search.toLowerCase()),
        ),
      );
    } else {
      setSearchedJobs(jobs);
    }
  }, [search]);

  useEffect(async () => {
    await sync();
  }, []);

  return (
    <div className="m-4">
      <SyncSpinner loading={syncing} />

      <PrimaryButton as={Link} to={route('jobs.add')} className="mb-12 w-full">
        <Icon path={mdiPlus} size={1} className="mr-1" /> Add Job
      </PrimaryButton>

      <ListItemWrapper searchName="Jobs" setSearch={setSearch} searchDisabled={syncing}>
        {searchedJobs.map((job) => (
          <ListItem
            key={job.id || job.temp_id}
            mainTitle={
              <div className="flex items-center gap-2">
                {(job.new || job.updated) && (
                  <div className={cls(job.updated ? 'bg-orange-500' : 'bg-red-500', 'rounded-2xl p-1')}>
                    <Icon path={mdiSync} size={0.7} className="text-white" />
                  </div>
                )}
                {job.name}
              </div>
            }
            as={Link}
            to={job.id || job.temp_id ? route('job', { id: job.id || job.temp_id }) : '#'}
          />
        ))}
      </ListItemWrapper>

      <WarningOfDataLimit />
    </div>
  );
};

export default Jobs;
