import { mdiChevronRight, mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import cls from 'classnames';
import MainAppButton from 'Components/Buttons/MainAppButton';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import FieldWrapper from 'Components/Forms/FieldWrapper';
import FormWrapper from 'Components/Forms/FormWrapper';
import TextField from 'Components/Forms/TextField';
import SyncSpinner from 'Components/SyncSpinner';
import { Form, Formik } from 'formik';

import React, { useEffect, useState } from 'react';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import useFormikSubmit from 'Support/hooks/useFormikSubmit';
import usePageTitle from 'Support/hooks/usePageTitle';
import * as Yup from 'yup';

const Settings = () => {
  usePageTitle('Settings');
  const isOnline = useOnlineStatus();
  const [data, setData] = useState([]);
  const [path, setPath] = useState('');
  const [syncing, setSyncing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formikProps, setFormikProps] = useState();
  const [updated, setUpdated] = useState(false);

  useEffect(async () => {
    if (isOnline && !syncing) {
      setSyncing(true);
      setData(await api.syncSettings());
    }

    setSyncing(false);
  }, [path]);

  useEffect(() => {
    if (typeof data === 'object' && Object.keys(data).length > 0) {
      setFormikProps({
        initialValues: {
          pwa_data_month_period: data.pwa_data_month_period,
        },
      });
      setLoading(false);
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setUpdated(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [updated]);
  const validationSchema = Yup.object().shape({
    pwa_data_month_period: Yup.number()
      .required('Please provide an Month Period of data collection')
      .max(4, 'Month Period of data collection must be less than 5 months')
      .min(1, 'Month Period of data collection must be greater than 0'),
  });

  const onSubmit = useFormikSubmit(async (values) => {
    const data = {
      ...values,
      updated: true,
    };
    await valueStore.set(`settings`, data);
    await api.syncSettings();
    setUpdated(true);
  });

  return (
    <div className="m-4">
      <SyncSpinner loading={syncing} />

      {isOnline && !loading ? (
        <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({ errors }) => {
            return (
              <Form>
                {updated && (
                  <div className="-m-4 mb-4 bg-green-200 p-2 text-center">
                    <p>Settings updated. Resync data on dashboard to view changes</p>
                  </div>
                )}
                <FormWrapper className={cls('-m-4 rounded-lg bg-white p-4 shadow-md')}>
                  <FieldWrapper>
                    <TextField
                      name="pwa_data_month_period"
                      label={
                        <>
                          <b>Data Sync</b>
                          <br />
                          Sets number of records to import on sync (between 1 - 4 months old). A lower setting may result in better performance.
                          Increase this if missing records. Note: does not affect 'Jobs' Records.
                          <br />
                          <br />
                          Always Import last
                        </>
                      }
                      type="number"
                    />
                    <span className="block text-sm font-medium leading-5 text-gray-700">
                      months of data.
                      <br />
                      <br />
                      To view any data older than 4 months, please
                      <MainAppButton className="flex text-primary" url="/">
                        visit the desktop application
                        <Icon path={mdiOpenInNew} size={0.5} className="ml-2" />
                      </MainAppButton>
                    </span>
                  </FieldWrapper>
                  <PrimaryButton type="submit" className="w-full" disabled={Object.values(errors).length}>
                    Submit <Icon path={mdiChevronRight} size={1} />
                  </PrimaryButton>
                </FormWrapper>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div>Settings can only be changed online</div>
      )}
    </div>
  );
};

export default Settings;
