import { mdiAlert } from '@mdi/js';
import Icon from '@mdi/react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import PopUp from 'Components/PopUp';
import React from 'react';

export const PreventSuperAdminSignIn = ({ isOpen, setOpen, setValue }) => {
  return (
    <PopUp setOpen={setOpen} isOpen={isOpen} canClose={false}>
      <div className="ml-3 mt-4 flex">
        <Icon path={mdiAlert} size={1} className="text-red-500" />
        <h4 className="px-3 pb-5 font-semibold tracking-wider text-gray-900" id="projects-headline">
          Warning - You cannot sign in as a super admin
        </h4>
      </div>
      <hr />
      <div className="flex h-full flex-col justify-between px-3">
        <div className="flex flex-col divide-y divide-gray-200 pb-4 pt-3">
          <p>A Super Admin cannot use the PWA/Mobile app, you must log in as a user with a company</p>
        </div>
        <div className="mb-4 flex justify-between space-x-5">
          <PrimaryButton
            type="button"
            onClick={() => {
              setValue(true);
              setOpen(false);
            }}
            className="h-full py-2"
          >
            logout
          </PrimaryButton>
        </div>
      </div>
    </PopUp>
  );
};
