import { mdiClipboardAccountOutline, mdiFileFindOutline, mdiFolderOpen, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import cls from 'classnames';
import SyncAllData from 'Components/SyncAllData';
import SyncSpinner from 'Components/SyncSpinner';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import api from 'Services/api';
import auth from 'Services/auth';
import valueStore from 'Services/valueStore';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const barOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      suggestedMax: 10,
    },
  },
};

export const DashboardCard = ({ children }) => (
  <div
    className={cls(
      'inline-flex w-full flex-col justify-between rounded-xl bg-white shadow-md transition duration-150 ease-in-out',
      'border-grey-100 border hover:text-primary',
    )}
  >
    {children}
  </div>
);

const DashboardButton = ({ dashboardValue }) => (
  <DashboardCard>
    <div className="flex w-full items-center justify-start space-x-2 p-4">
      <div className="rounded-xl bg-primary p-4 text-white">
        <Icon path={dashboardValue.icon} size={1} />
      </div>
      <div className="flex w-full items-center justify-between">
        <div className="font-semibold">
          <h4 className="w-full text-gray-500">{dashboardValue.text}</h4>
          <h3 className="text-xl font-bold text-gray-900">{dashboardValue.metric}</h3>
        </div>
      </div>
    </div>
    <div className="relative">
      <Link to={dashboardValue.primaryRoute} as="button" className="flex w-full items-center justify-between rounded-b-xl bg-gray-100 px-4 py-2">
        <p className="text-primary">View All</p>
      </Link>
      <Link to={dashboardValue.secondaryRoute} as="button" className="absolute right-1 top-1/2 -translate-y-1/2 rounded-lg p-2 text-primary">
        <Icon path={mdiPlus} size={0.75} />
      </Link>
    </div>
  </DashboardCard>
);

const icons = {
  tasks: mdiClipboardAccountOutline,
  folder: mdiFolderOpen,
  search: mdiFileFindOutline,
};

const Dashboard = () => {
  usePageTitle('Dashboard');
  const isOnline = useOnlineStatus();
  const [syncing, setSyncing] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const [barChartData, setBarChartData] = useState(null);

  const setBarChartDataState = (data) => {
    let newData = {
      datasets: auth.getOrganizationUsesSSSP()
        ? [
            {
              label: 'Incident reports',
              data: [],
              backgroundColor: `${window.themeRed}`,
            },
            {
              label: 'Task Analyses',
              data: [],
              backgroundColor: `${window.themePrimary}`,
            },
          ]
        : [
            {
              label: 'Task Analyses',
              data: [],
              backgroundColor: `${window.themePrimary}`,
            },
          ],
      labels: [],
    };

    for (let i = 0; i < data.length; i++) {
      newData.labels.push(data[i].label);
      if (auth.getOrganizationUsesSSSP()) {
        newData.datasets[0].data.push(data[i].incident_report);
        newData.datasets[1].data.push(data[i].task_analysis);
      } else {
        newData.datasets[0].data.push(data[i].task_analysis);
      }
    }
    setBarChartData(newData);
  };

  const sync = async () => {
    if (isOnline && !syncing) {
      setSyncing(true);
      // setTasks(await api.syncTasks());
      setMetrics(await api.syncDashboardMetrics());
      setBarChartDataState(await api.syncDashboardBarChart());
    } else {
      // setTasks(await valueStore.get('tasks'));
      setMetrics(await valueStore.getArray('dashboardMetrics'));
      setBarChartDataState(await valueStore.getArray('dashboardBarChartData'));
    }

    setSyncing(false);
  };

  useEffect(async () => {
    await sync();
  }, []);

  return (
    <div className="m-4 space-y-4">
      <SyncSpinner loading={syncing} />
      <SyncAllData loading={syncing} />
      <div className="space-y-4">
        {metrics.map((metric, index) => (
          <DashboardButton
            key={index}
            dashboardValue={{
              text: metric.title,
              primaryRoute: route(metric.primaryRoute[0], metric.primaryRoute[1]),
              secondaryRoute: route(metric.secondaryRoute[0], metric.secondaryRoute[1]),
              icon: icons[metric.icon],
              metric: metric.value,
            }}
          />
        ))}

        <DashboardCard>{barChartData && <Bar options={barOptions} data={barChartData} className="p-4" />}</DashboardCard>
      </div>

      {/*<div className="mx-4">*/}
      {/*  <TasksList heading="My Tasks" tasks={tasks}/>*/}
      {/*</div>*/}
    </div>
  );
};

export default Dashboard;
