import { mdiArrowLeft, mdiChevronRight, mdiClipboardListOutline, mdiComment } from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import FieldWrapper from 'Components/Forms/FieldWrapper';
import FormWrapper from 'Components/Forms/FormWrapper';
import SelectField from 'Components/Forms/SelectField';
import ViewContent from 'Components/Forms/ViewContent';
import ViewLabel from 'Components/Forms/ViewLabel';
import SyncSpinner from 'Components/SyncSpinner';
import { format, parseJSON } from 'date-fns';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import useFormikSubmit from 'Support/hooks/useFormikSubmit';
import usePageTitle from 'Support/hooks/usePageTitle';
import usePermissions from 'Support/hooks/usePermissions';
import route from 'Support/route';
import * as Yup from 'yup';

const jobClass = 'App\\Models\\Job';
const reportClass = 'App\\Models\\Report';
const viewLogsClass = 'App\\Models\\ViewLog';

export const taskStatusOptions = [
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'done',
    label: 'Done',
  },
];

const entityTypeOptions = [
  {
    value: null,
    label: '---',
    link: '#',
  },
  {
    value: jobClass,
    label: 'Job',
    link: '/job/',
  },
  {
    value: reportClass,
    label: 'Report',
    link: '/reports/edit/',
  },
  {
    value: viewLogsClass,
    label: 'View Logs',
    link: '#',
  },
];

const EditTask = () => {
  const { id } = useParams();
  const history = useHistory();

  const isOnline = useOnlineStatus();
  const [currentTask, setCurrentTask] = useState([]);
  const [entityOptions, setEntityOptions] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [reports, setReports] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [syncing, setSyncing] = useState(false);
  const [formikProps, setFormikProps] = useState();
  const [loading, setLoading] = useState(true);
  const permissions = usePermissions();

  const sync = async () => {
    if (!syncing && isOnline) {
      setSyncing(true);

      setJobs(await api.syncJobs());
      setUsers(await api.syncUsers());
      setReports(await api.syncReports());

      setSyncing(false);
    }
  };

  useEffect(async () => {
    setJobs((await valueStore.getArray(`jobs`)) || []);
    setTasks((await valueStore.getArray(`tasks`)) || []);
    setUsers((await valueStore.getArray(`users`)) || []);
    setReports((await valueStore.getArray(`reports`)) || []);
  }, []);

  usePageTitle(`Edit Task`);

  const validationSchema = Yup.object().shape({});

  const onSubmit = useFormikSubmit(async (values) => {
    let newTask = {
      ...currentTask,
      ...values,
      updated: true,
    };

    let tasksForUpload = tasks.map((task) => {
      if (parseInt(id) === task.id || id === task.temp_id) {
        return newTask;
      }
      return task;
    });

    setTasks(tasksForUpload);

    await valueStore.set(`tasks`, tasksForUpload);

    history.push(route('tasks'));
  });

  useEffect(async () => {
    setCurrentTask(
      tasks?.find((task) => {
        if (task.id) {
          return task.id === parseInt(id);
        }
        return task.temp_id === id;
      }),
    );
  }, [JSON.stringify(tasks)]);

  useEffect(async () => {
    if (isOnline) {
      await sync();
    }
  }, [JSON.stringify(tasks)]);

  useEffect(() => {
    if ((currentTask?.id || currentTask?.temp_id) && !formikProps?.initialValues) {
      setFormikProps({
        initialValues: {
          status: currentTask?.status?.value || '',
        },
      });
      setLoading(false);
    }
  }, [JSON.stringify(currentTask)]);

  useEffect(() => {
    if (currentTask?.entity_type === jobClass) {
      setEntityOptions(jobs);
    }
    if (currentTask?.entity_type === reportClass) {
      setEntityOptions(reports);
    }
  }, [jobs, reports]);

  return (
    <div className="m-4">
      <SyncSpinner loading={loading} />

      <SecondaryButton as={Link} to={route('tasks')} className="mb-12 w-full py-2">
        <Icon path={mdiArrowLeft} size={1} className="mr-1" /> Back
      </SecondaryButton>

      {!loading && (
        <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({ errors }) => (
            <Form>
              <FormWrapper className="rounded-lg bg-white shadow-md">
                <FieldWrapper>
                  <ViewLabel text="Due At" />
                  {currentTask?.due_at && (
                    <ViewContent
                      className="col-span-2"
                      text={
                        currentTask?.due_at === 'now' || parseJSON(currentTask?.due_at).toString() === 'Invalid Date'
                          ? format(Date.now(), 'dd/MM/yyyy hh:mmaaa')
                          : format(parseJSON(currentTask?.due_at), 'dd/MM/yyyy hh:mmaaa')
                      }
                    />
                  )}
                </FieldWrapper>

                <FieldWrapper>
                  <ViewLabel text="Description" />
                  <ViewContent className="col-span-2" text={currentTask?.description} />
                </FieldWrapper>

                {currentTask?.entity_type || currentTask?.entity_id ? (
                  <>
                    <FieldWrapper>
                      <ViewLabel text="Link to" />
                      <ViewContent
                        className="col-span-2"
                        text={entityTypeOptions?.find((option) => option.value === currentTask?.entity_type)?.label}
                      />
                    </FieldWrapper>
                    {entityOptions.length ? (
                      <FieldWrapper>
                        <ViewLabel text={entityTypeOptions?.find((option) => option.value === currentTask?.entity_type)?.label} />
                        <a
                          href={
                            entityTypeOptions?.find((option) => option.value === currentTask?.entity_type)?.link + currentTask?.entity_id?.toString()
                          }
                          className="col-span-2 text-primary underline"
                        >
                          {entityOptions?.find((option) => option.id === currentTask?.entity_id)?.name}
                        </a>
                      </FieldWrapper>
                    ) : null}
                  </>
                ) : null}

                {users.length > 0 && (
                  <FieldWrapper>
                    <ViewLabel text="Assignee" />
                    <ViewContent className="col-span-2" text={users?.find((user) => user.id === currentTask?.assignee_id)?.name} />
                  </FieldWrapper>
                )}

                <FieldWrapper>
                  <SelectField name="status" options={taskStatusOptions} />
                </FieldWrapper>

                {currentTask?.recurring_id && permissions.update_recurring_task && (
                  <SecondaryButton as={Link} className="p-2" to={route('tasks.recurring.edit', { id: currentTask.id })}>
                    View Repeat Task Details <Icon path={mdiClipboardListOutline} className="ml-3" size={1} />
                  </SecondaryButton>
                )}
                <SecondaryButton as={Link} className="p-2" to={route('tasks.comments', { id: currentTask.id })}>
                  View Comments <Icon path={mdiComment} className="ml-3" size={1} />
                </SecondaryButton>

                <FieldWrapper className="mt-6 grid grid-cols-2 gap-4">
                  <div />
                  <PrimaryButton type="submit" className="w-full" disabled={Object.values(errors).length}>
                    Submit <Icon path={mdiChevronRight} size={1} />
                  </PrimaryButton>
                </FieldWrapper>
              </FormWrapper>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditTask;
