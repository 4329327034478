import React, { createContext, useState } from 'react';

/**
 * @type {React.Context<{pageTitle: string}>}
 */
export const LayoutContext = createContext({
  pageTitle: '',
});

export const LayoutContextProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState('');

  return (
    <LayoutContext.Provider
      value={{
        pageTitle,
        setPageTitle,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
