import { AxiosError } from 'axios';
import { useCallback } from 'react';

const useFormikSubmit = (submitHandler) =>
  useCallback(
    async (values, { setErrors }) => {
      try {
        return await submitHandler(values);
      } catch (/** @type {AxiosError} */ e) {
        if (e.response) {
          if (e.response.status === 422) {
            const errors = e.response.data.errors;
            const firstErrors = Object.keys(errors).reduce((map, key) => ({ [key]: errors[key][0], ...map }), {});
            setErrors(firstErrors);
          }
        } else {
          throw e;
        }
      }
    },
    [submitHandler],
  );

export default useFormikSubmit;
