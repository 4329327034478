import cls from 'classnames';
import ReactSelectField from 'Components/Forms/ReactSelectField';
import { ErrorMessage, Field } from 'formik';
import React from 'react';
export const riskRatingValues = [
  {
    value: 5,
    label: 'Very Low',
  },
  {
    value: 10,
    label: 'Low',
  },
  {
    value: 15,
    label: 'Moderate',
  },
  {
    value: 20,
    label: 'High',
  },
  {
    value: 25,
    label: 'Critical',
  },
];

const SelectField = ({ name, label = null, className = '', ...props }) => {
  return (
    <>
      <Field
        component={ReactSelectField}
        options={riskRatingValues}
        className={cls('block w-full rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5', className)}
        name={name}
        {...props}
      />
      <ErrorMessage name={name} component="div" className="font-content text-xs italic text-red-500" />
    </>
  );
};

export default SelectField;
