import { Transition } from '@headlessui/react';
import { mdiAccountCheckOutline, mdiClose, mdiFormatListChecks, mdiLogoutVariant, mdiOpenInNew } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import MainAppButton from 'Components/Buttons/MainAppButton';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import routes from 'routes';
import auth from 'Services/auth';
import usePermissions from 'Support/hooks/usePermissions';
import useUser from 'Support/hooks/useUser';
import route from 'Support/route';
import theme from 'tailwind-theme';

const BackgroundOverlay = ({ show, ...props }) => (
  <Transition.Child
    as="div"
    {...props}
    enter="ease-in-out duration-200"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in-out duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
  />
);

const PanelWrapper = ({ ...props }) => <div className="absolute inset-y-0 left-0 flex w-64 max-w-screen-9/10" {...props} />;

const Panel = ({ show, ...props }) => (
  <Transition.Child
    as="div"
    {...props}
    enter="transform transition ease-in-out duration-300"
    enterFrom="-translate-x-full"
    enterTo="-translate-x-0"
    leave="transform transition ease-in-out duration-300"
    leaveFrom="-translate-x-0"
    leaveTo="-translate-x-full"
    className="relative w-screen max-w-md"
  />
);

const CloseButton = ({ onClick }) => (
  <div className="absolute right-0 top-0 -mr-10 flex pl-4 pr-2 pt-4">
    <button aria-label="Close menu" className="text-gray-300 transition duration-150 ease-in-out hover:text-white" onClick={onClick}>
      <Icon path={mdiClose} size={theme.fontSize['2xl'][0]} />
    </button>
  </div>
);

const menuItemDefaultClasses =
  'px-2 py-2 text-sm leading-6 font-medium rounded-md transition ease-in-out duration-150 flex items-center group focus:outline-none';
const menuItemActiveClasses = 'bg-primary text-white';
const menuItemInactiveClasses = 'hover:bg-primary focus:bg-primary hover:text-white focus:text-white text-primary';
const iconActiveClasses = 'text-white';
const iconInactiveClasses = 'text-primary';

const MenuItem = ({ to, title, icon, children, exact, className, ...props }) => {
  let match = useRouteMatch({ path: to, exact: exact });

  return (
    <Link className={cls(menuItemDefaultClasses, match ? menuItemActiveClasses : menuItemInactiveClasses, className)} to={to} {...props}>
      <Icon path={icon} size={1} className={cls('mr-2', match ? iconActiveClasses : iconInactiveClasses)} /> {children || title}
    </Link>
  );
};

const Account = () => {
  const logout = async () => {
    await auth.logout();
    window.location.href = route('login');
  };

  return (
    <div className="flex justify-between bg-primary px-4 py-2 text-sm text-white">
      <p className="flex space-x-2 text-white hover:text-white focus:text-white focus:outline-none">Version: {window.version}</p>
      <button className="ml-auto flex space-x-2 text-white hover:text-white focus:text-white focus:outline-none" onClick={logout}>
        <p>Logout</p>
        <Icon path={mdiLogoutVariant} weight={0.5} size={0.75} />
      </button>
    </div>
  );
};

const displayRoute = (removeForSuperAdmin) => {
  const permissions = usePermissions();

  return !(!permissions.show_routes_organization && removeForSuperAdmin);
};

/**
 * @param {boolean} isOpen
 * @param {function(boolean|function(boolean): boolean)} setOpen
 * @returns {JSX.Element}
 * @constructor
 */
const Sidenav = ({ isOpen, setOpen }) => {
  const closeNav = () => setOpen(false);
  const user = useUser();
  const permissions = usePermissions();

  return (
    <Transition show={isOpen} className="fixed inset-0 z-10 overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <BackgroundOverlay show={isOpen} onClick={closeNav} />

        <PanelWrapper>
          <Panel show={isOpen}>
            <CloseButton onClick={closeNav} />

            <div className="flex h-full flex-col overflow-y-auto bg-white shadow-xl">
              <div className="bg-primary p-4">
                <Link className="flex h-16 shrink-0 items-center justify-center px-4 focus:outline-none" to="/">
                  <img src="/icon/logo-light.png/1" alt="Logo" className="max-h-12" />
                </Link>
                <div>
                  <p className="text-center text-white">Hi {user?.name}</p>
                </div>
              </div>
              <div className="relative mt-6 flex h-full flex-1 flex-col justify-between px-2 pb-4">
                <div className="space-y-1">
                  {routes
                    .filter((route) => route.menuTitle && displayRoute(route.removeForSuperAdmin) && !route.bottom)
                    .map((route) => (
                      <MenuItem
                        key={route.name}
                        icon={route.menuIcon}
                        title={route.menuTitle}
                        to={route.menuPath ? route.menuPath : route.path}
                        exact={route.exact}
                      />
                    ))}
                  <MainAppButton className={cls(menuItemDefaultClasses, menuItemInactiveClasses)} url={'/hazards/my-hazards'}>
                    <Icon path={mdiFormatListChecks} size={1} className={cls('mr-2', iconInactiveClasses)} />
                    Register
                    <Icon path={mdiOpenInNew} size={0.5} className={cls('ml-2', iconInactiveClasses)} />
                  </MainAppButton>
                  <MainAppButton
                    className={cls(menuItemDefaultClasses, menuItemInactiveClasses)}
                    url={!permissions.edit_trainings_user ? '/account/induction' : '/users'}
                  >
                    <Icon path={mdiAccountCheckOutline} size={1} className={cls('mr-2', iconInactiveClasses)} />
                    Induction
                    <Icon path={mdiOpenInNew} size={0.5} className={cls('ml-2', iconInactiveClasses)} />
                  </MainAppButton>
                </div>
                {routes
                  .filter((route) => route.menuTitle && displayRoute(route.removeForSuperAdmin) && route.bottom)
                  .map((route) => (
                    <MenuItem
                      key={route.name}
                      icon={route.menuIcon}
                      className="mt-6"
                      title={route.menuTitle}
                      to={route.menuPath ? route.menuPath : route.path}
                      exact={route.exact}
                    />
                  ))}
              </div>

              <Account user={user} />
            </div>
          </Panel>
        </PanelWrapper>
      </div>
    </Transition>
  );
};

export default Sidenav;
