import cls from 'classnames';
import OptionalLabel from 'Components/Forms/OptionalLabel';
import ReactSelectField from 'Components/Forms/ReactSelectField';
import { ErrorMessage, Field } from 'formik';
import React from 'react';

const SelectField = ({ name, label = null, className = '', labelClasses, wrappingClassName, ...props }) => {
  if (!Array.isArray(props.options)) {
    props.options = Object.entries(props.options).map(([value, label]) => ({ label, value }));
  }

  return (
    <div className={wrappingClassName}>
      <OptionalLabel name={name} label={label} labelClasses={labelClasses} required={props.isRequired} />
      <Field
        component={ReactSelectField}
        className={cls('block w-full rounded-md shadow-sm transition duration-150 ease-in-out sm:text-sm sm:leading-5', className)}
        name={name}
        {...props}
      />
      <ErrorMessage name={name} component="div" className="font-content text-xs italic text-red-500" />
    </div>
  );
};

export default SelectField;
