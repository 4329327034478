import cls from 'classnames';
import React from 'react';

const StatusBadges = ({ status }) => {
  let defaultClasses = 'px-2 rounded-full w-auto';
  switch (status) {
    case 'New':
      return <span className={cls('bg-blue-200 text-blue-900', defaultClasses)}>{status}</span>;
    case 'Active':
      return <span className={cls('rounded-full bg-green-200 px-2 text-green-900', defaultClasses)}>{status}</span>;
    case 'Open':
      return <span className={cls('rounded-full bg-yellow-200 px-2 text-yellow-900', defaultClasses)}>{status}</span>;
    case 'Resolved':
      return <span className={cls('rounded-full bg-green-900 px-2 text-green-200', defaultClasses)}>{status}</span>;
    case 'To be synced':
      return <span className={cls('rounded-full bg-red-200 px-2 text-red-900', defaultClasses)}>{status}</span>;
    case undefined:
      return null;
    default:
      return <span className={cls('rounded-full bg-green-200 px-2 text-green-900', defaultClasses)}>{status}</span>;
  }
};

export default StatusBadges;
