import { mdiAlert, mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';
import BottomNav from 'Components/BottomNav';
import Header from 'Components/Header';
import Sidenav from 'Components/Sidenav';
import SwitchOrganizationNav from 'Components/SwitchOrganizationNav';
import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { JobContextProvider } from 'Support/Contexts/JobContext';
import PrimaryButton from '../Components/Buttons/PrimaryButton';
import SecondaryButton from '../Components/Buttons/SecondaryButton';
import PopUp from '../Components/PopUp';

export const ErrorContext = createContext({
  title: '',
  message: '',
});

const MainLayout = ({ children }) => {
  const [navOpen, setNavOpen] = useState(false);
  const [orgSwitchOpen, setOrgSwitchOpen] = useState(false);
  const bottomNavRef = useRef();
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorOpen, setErrorOpen] = useState(false);

  useEffect(() => {
    if (bottomNavRef.current) {
      bottomNavRef.current.style.bottom = '0';
      let previousScrollPos;
      const handleScroll = (event) => {
        let currentScrollPos = window.scrollY;
        if (bottomNavRef.current) {
          if (previousScrollPos > currentScrollPos || !previousScrollPos) {
            bottomNavRef.current.style.bottom = '0';
          } else {
            bottomNavRef.current.style.bottom = '-100px';
          }
        }

        previousScrollPos = currentScrollPos;
      };
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const errorContextFields = useMemo(
    () => ({
      title: (title) => setErrorTitle(title),
      message: (message) => setErrorMessage(message),
      setOpen: (open) => setErrorOpen(open),
    }),
    [errorOpen],
  );

  return (
    <JobContextProvider>
      <ErrorContext.Provider value={errorContextFields}>
        <PopUp setOpen={setErrorOpen} isOpen={errorOpen} canClose={false}>
          <div className="ml-3 mt-4 flex">
            <Icon path={mdiAlert} size={1} className="text-red-500" />
            <h4 className="w-full px-3 pb-5 font-semibold tracking-wider text-gray-900" id="projects-headline">
              {errorTitle}
            </h4>
          </div>
          <hr />
          <div className="flex h-full flex-col justify-between px-3">
            <div className="flex flex-col divide-y divide-gray-200 pb-4 pt-3">
              <p>{errorMessage}</p>
            </div>
            <div className="mb-4 flex justify-between space-x-5">
              <PrimaryButton
                type="button"
                onClick={() => {
                  // setDisabled(true);
                  // setOpen(false, true);
                }}
                className="h-full py-2"
              >
                {/*{disabled && <Icon path={mdiLoading} size={1} className="animate-spin text-white mr-2"/>}*/}
                OK
              </PrimaryButton>
            </div>
          </div>
        </PopUp>
        <main className="min-h-screen bg-gray-100 pb-20" onScroll={() => console.log('scroll')}>
          <Header isNavOpen={navOpen} setNavOpen={setNavOpen} setSwitchOrgOpen={setOrgSwitchOpen} />
          <Sidenav isOpen={navOpen} setOpen={setNavOpen} />
          <SwitchOrganizationNav isOpen={orgSwitchOpen} setOpen={setOrgSwitchOpen} />
          <div>{children}</div>
          <div ref={bottomNavRef} className="fixed block w-full duration-300">
            <BottomNav />
          </div>
        </main>
      </ErrorContext.Provider>
    </JobContextProvider>
  );
};

export default MainLayout;
