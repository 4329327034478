import { generatePath } from 'react-router';
import routes from 'routes';
import { findBestMatch } from 'string-similarity';

export const routeByName = (name) => {
  const routeDefinition = routes.find((route) => route.name === name);
  if (!routeDefinition) {
    if (process.env.NODE_ENV === 'development') {
      const {
        bestMatch: { target: bestMatch },
      } = findBestMatch(
        name,
        routes.map((route) => route.name),
      );
      throw new Error(`No route with name '${name}'.\nDid you mean '${bestMatch}'? `);
    } else {
      throw new Error(`No route with name '${name}'.`);
    }
  }

  return routeDefinition;
};

const route = (name, params = {}) => {
  const routeDefinition = routeByName(name);

  try {
    return generatePath(routeDefinition.path, params);
  } catch (e) {
    const regex = /Expected "(.*)" to be defined/;
    const match = regex.exec(e.message);
    if (match.length) {
      throw new Error(`Failed to generate route for '${name}'.\nParameter '${match[1]}' is missing.`);
    } else {
      e.message = `Failed to generate route for '${name}'.\n${e.message}`;
      throw e;
    }
  }
};

export default route;
