import { mdiArrowRight, mdiMinus, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import CompactButton from 'Components/Buttons/CompactButton';
import { Field } from 'formik';
import RiskDropdown from 'Pages/Jobs/RiskDropdown';
import React, { useLayoutEffect, useRef, useState } from 'react';

const HazardField = ({ hazards, suggestions, index, setFieldValue }) => {
  const [showAddRiskControlInput, setShowAddRiskControlInput] = useState(false);
  const inputField = useRef();

  const isRequired = () => {
    if (hazards[index]?.description) {
      return hazards[index]?.description !== undefined;
    }
    return false;
  };

  const onInput = (event) => {
    const hazard = suggestions?.find((hazard) => hazard.description === event.target.value);

    if (hazard) {
      hazards[index] = hazard;
    } else {
      hazards[index] = {
        hazard_controls: [],
        description: event.target.value,
        initial_risk_rating: '',
        residual_risk_rating: '',
      };
    }

    setFieldValue('hazards', hazards);
  };

  useLayoutEffect(() => {
    showAddRiskControlInput && inputField.current.focus();
  }, [showAddRiskControlInput]);

  const onKeyDown = (e) => {
    if (e.charCode === 13 || e.keyCode === 13) {
      addRiskControl();
    }
  };

  const addRiskControl = () => {
    const value = inputField.current.value;
    if (value.length) {
      if (hazards[index].hazard_controls) {
        typeof hazards[index].hazard_controls === 'undefined' && (hazards[index].hazard_controls = []);

        hazards[index].hazard_controls.push(value);
      } else {
        typeof hazards[index].hazard_controls === 'undefined' && (hazards[index].hazard_controls = []);

        hazards[index].hazard_controls.push(value);
      }
    }

    setFieldValue('hazards', hazards);
    inputField.current.value = '';
    setShowAddRiskControlInput(false);
  };

  const delRiskControl = (i) => {
    if (hazards[index].hazard_controls) {
      hazards[index].hazard_controls.splice(i, 1);
      setFieldValue('hazards', hazards);
      setShowAddRiskControlInput(false);
    } else {
      hazards[index].hazard_controls.splice(i, 1);
      setFieldValue('hazards', hazards);
      setShowAddRiskControlInput(false);
    }
  };

  const hazardControlItem = (hazardControl, index) => (
    <li key={index} className="flex cursor-pointer select-none items-center justify-between text-sm font-medium leading-5 text-gray-800">
      <div>{hazardControl}</div>
      <CompactButton className="border border-gray-300" onClick={() => delRiskControl(index)}>
        <Icon path={mdiMinus} size={0.5} />
      </CompactButton>
    </li>
  );

  return (
    <div className="space-y-2 rounded bg-white p-4">
      <Field
        placeholder="Add new Hazard"
        className="focus:shadow-outline-blue block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
        name={`hazards.${index}.description`}
        list="suggestions-hazards"
        onInput={onInput}
      />

      <label className="flex space-x-2 space-y-2">
        <div className="flex items-center justify-between text-sm text-gray-800">Initial Risk Rating</div>
        {isRequired() && <span className="text-red-500">*</span>}
      </label>
      {/*risk_rating only appears on values that already exist in the Hazard register*/}
      <RiskDropdown name={hazards[index].initial_risk_rating ? `hazards.${index}.initial_risk_rating` : `hazards.${index}.risk_rating`} />

      <label className="block space-y-2">
        <div className="flex items-center justify-between text-sm text-gray-800">
          Risk Controls
          <CompactButton className="border border-gray-300" onClick={() => setShowAddRiskControlInput(true)}>
            <Icon path={mdiPlus} size={0.5} />
          </CompactButton>
        </div>
        <ul className="list-outside list-disc space-y-2 pl-5">
          {hazards[index].hazard_controls
            ? hazards[index].hazard_controls.map((hazardControl, index) => hazardControlItem(hazardControl, index))
            : hazards[index].hazard_controls.map((hazardControl, index) => hazardControlItem(hazardControl, index))}
          <li
            key={index}
            className={cls(
              'flex cursor-pointer select-none items-center justify-between text-sm font-medium leading-5 text-gray-800',
              !showAddRiskControlInput && 'hidden',
            )}
          >
            <input
              ref={inputField}
              className="focus:shadow-outline-blue block h-6 appearance-none rounded-md border border-gray-300 px-3 py-2 pl-4 placeholder-gray-400 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
              placeholder="New risk control"
              onKeyDown={onKeyDown}
            />
            <CompactButton className="border border-gray-300" onClick={addRiskControl}>
              <Icon path={mdiArrowRight} size={0.5} />
            </CompactButton>
          </li>
        </ul>
      </label>

      {typeof hazards[index].residual_risk_rating !== 'undefined' && (
        <>
          <label className="flex space-x-2 space-y-2">
            <div className="flex items-center justify-between text-sm text-gray-800">Residual Risk Rating</div>
            {isRequired() && <span className="text-red-500">*</span>}
          </label>
          <RiskDropdown name={`hazards.${index}.residual_risk_rating`} />
        </>
      )}
    </div>
  );
};

export default HazardField;
