import OptionalLabel from 'Components/Forms/OptionalLabel';
import { defaultInputStyles } from 'Components/Forms/TextField';
import { SelectCreate } from 'Components/SelectCreate';
import { useMemo } from 'react';

const MultiField = ({ name, values, push, insert, label, placeholder, required = false, suggestions = null, valueKey = 'description' }) => {
  const options = useMemo(() => {
    if (!suggestions) return [];
    return suggestions.filter((option) => !values.some((value) => value[valueKey].toLowerCase().includes(option.toLowerCase())));
  }, [suggestions, values]);

  return (
    <div className="space-y-1">
      <OptionalLabel required={required} name={name} label={label} />

      {values.map((item, index) => (
        <SelectCreate
          key={item[valueKey] ?? index}
          type={name}
          options={options}
          value={item[valueKey]}
          onChange={(value) => {
            insert(index, { [`${valueKey}`]: value });
          }}
          className={defaultInputStyles}
        />
      ))}
      <SelectCreate
        key={`empty-${name}-field`}
        type={name}
        options={options}
        value=""
        onChange={(value) => {
          insert(values.length, { [`${valueKey}`]: value });
        }}
        className={defaultInputStyles}
      />
    </div>
  );
};

export default MultiField;
