import { useField } from 'formik';
import React, { useEffect } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { isEmptyValue } from 'Support/valueHelpers';
import theme from 'tailwind-theme';

export const defaultSelectStyles = {
  container: (provided, state) => ({}),
  control: (provided, state) => ({
    ...provided,
    borderRadius: 5,
    paddingLeft: 10,
    minHeight: theme.height['10.5'],
    ...(state.isFocused
      ? {
          '--tw-border-opacity': 1,
          borderColor: 'rgba(38, 198, 218, var(--tw-border-opacity))',
          outline: '2px solid transparent',
          outlineOffset: '2px',
          '--tw-ring-offset-shadow': 'var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color)',
          '--tw-ring-shadow': 'var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color)',
          boxShadow: 'var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000)',
          '--tw-ring-color': 'rgba(38, 198, 218, var(--tw-ring-opacity))',
          '--tw-ring-opacity': 0.2,
          '&:hover': {
            borderColor: 'rgba(38, 198, 218, var(--tw-border-opacity))',
          },
        }
      : {
          boxShadow: theme.boxShadow.input,
          backgroundColor: 'white',
          borderColor: theme.colors.gray[300],
          '&:hover': {
            borderColor: theme.colors.gray[600],
          },
        }),
  }),
  input: (provided, state) => ({
    ...provided,
    color: theme.colors.blackish,
    fontSize: theme.fontSize['sm'][0],
    'input:focus': {
      boxShadow: 'none',
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: theme.colors.gray[800],
    fontSize: theme.fontSize['sm'][0],
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: theme.colors.blackish,
    fontSize: theme.fontSize['sm'][0],
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: theme.colors.transparent,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: theme.colors.blackish,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    color: theme.colors.blackish,
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: 5,
    color: theme.colors.blackish,
    fontSize: theme.fontSize['sm'][0],
    backgroundColor: 'white',
    position: 'static',
    marginTop: 0,
    marginBottom: 0,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const ReactSelectField = ({ valueKey, labelKey, ...props }) => {
  const [field, state, { setValue, setTouched }] = useField(props.field.name);

  const modifiedProps = {
    ...props,
    getOptionValue: props.getOptionValue || ((option) => option[valueKey === undefined ? 'value' : valueKey]),
  };

  if (labelKey !== undefined) {
    modifiedProps.getOptionLabel = (option) => option[labelKey];
  }

  let defaultValue;
  let hideSelectedOptions;

  if (modifiedProps.isMulti) {
    defaultValue = field.value;
    hideSelectedOptions = true;
  } else {
    const hasSubOptions = modifiedProps?.options?.some((option) => 'options' in option);
    const optionList = hasSubOptions ? modifiedProps?.options?.flatMap((group) => group.options) : props.options;
    defaultValue = optionList.find((option) => modifiedProps?.getOptionValue(option) === field.value);
  }

  // If we have a selected value, but we cannot find it in the options list then it means the options
  // have changed and it's no longer available, so let formik know
  useEffect(() => {
    if (!isEmptyValue(field.value) && defaultValue === undefined) {
      setValue(null);
    }
  }, [defaultValue, field.value]);

  const onChange = (option, meta) => {
    // block removal of options if they are fixed
    if (meta.action === 'remove-value' || meta.action === 'pop-value') {
      if (typeof meta.removedValue === 'object' && meta.removedValue?.fixed) {
        return;
      }
    }

    const value = Array.isArray(option) ? option : isEmptyValue(option) ? null : modifiedProps.getOptionValue(option);
    setTouched(true);
    setValue(value, true);
    modifiedProps.onChange?.(value);
  };

  if (defaultValue === undefined) {
    defaultValue = null;
  }

  const SelectComponent = typeof props.loadOptions === 'function' ? AsyncSelect : Select;

  return (
    <SelectComponent
      {...modifiedProps}
      value={defaultValue}
      styles={defaultSelectStyles}
      onChange={onChange}
      components={props.components}
      onBlur={() => setTouched(true)}
      hideSelectedOptions={hideSelectedOptions}
      instanceId={`react-select-${modifiedProps.field.name}`}
    />
  );
};

export default ReactSelectField;
