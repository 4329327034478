import { mdiMagnify } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import { defaultInputStyles } from 'Components/Forms/TextField';
import React from 'react';

const ListItemWrapper = ({ children, className, searchName, setSearch, searchDisabled }) => (
  <div className={cls('-mx-4 divide-y divide-gray-100 rounded-md bg-white bg-white p-4 shadow-lg', className)}>
    {searchName && (
      <div className="border-b-1 relative border-gray-100 pb-4">
        <input
          onChange={(e) => setSearch(e.target.value)}
          className={cls(defaultInputStyles, 'pl-10')}
          placeholder={'Search ' + searchName}
          disabled={searchDisabled}
          type="search"
        />
        <Icon path={mdiMagnify} size={1} className="absolute left-3 top-2 text-gray-300" />
      </div>
    )}
    {children}
  </div>
);

export default ListItemWrapper;
