import React, { createElement } from 'react';
import { Redirect, Route as RouterRoute } from 'react-router-dom';
import auth from 'Services/auth';
import route from 'Support/route';

export const AuthenticatedRoute = ({ component, layout, ...props }) => {
  if (!auth.isAuthenticated()) {
    return <Redirect to={route('login')} />;
  }

  return <Route component={component} layout={layout} {...props} />;
};

export const GuestRoute = ({ component, layout, ...props }) => {
  //check route is not /auth/logout
  if (auth.isAuthenticated() && props.path !== route('logout')) {
    return <Redirect to={route('dashboard')} />;
  }

  return <Route component={component} layout={layout} {...props} />;
};

export const Route = ({ component: PageComponent, layout: PageLayout, ...props }) => (
  <RouterRoute {...props}>{PageLayout ? <PageLayout children={createElement(PageComponent)} /> : <PageComponent />}</RouterRoute>
);
