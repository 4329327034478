import cls from 'classnames';
import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import route from 'Support/route';

const WarningOfDataLimit = ({ className = '' }) => {
  return (
    <div className={cls('-mx-4 mt-4 rounded-md bg-white p-4 shadow-lg', className)}>
      <p>
        Your data is limited by a period of time. To change this go to your{' '}
        <Link to={route('settings')} className="text-primary">
          Settings
        </Link>{' '}
      </p>
    </div>
  );
};

export default WarningOfDataLimit;
