import useOnlineStatus from '@rehooks/online-status';
import MultiField from 'Components/Forms/MultiField.jsx';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import { useEffect, useState } from 'react';

const AttendeesList = ({ attendees, push, insert, required = false, ...props }) => {
  const isOnline = useOnlineStatus();
  const [suggestions, setSuggestions] = useState([]);

  const sync = () => {
    const getAttendees = async () => await api.fetchAttendees();

    getAttendees().then(async (attendeesList) => {
      const values = attendeesList.map((attendee) => attendee.name);
      setSuggestions(values);
      await valueStore.set(`attendees`, values);
    });
  };

  useEffect(async () => {
    if (isOnline) {
      sync();
    } else {
      const attendees = await valueStore.getArray(`attendees`);
      setSuggestions(attendees);
    }
  }, []);

  return (
    <MultiField
      name="attendees"
      values={attendees}
      push={push}
      label="Attendees"
      insert={insert}
      placeholder="Add new Attendee"
      required={required}
      suggestions={suggestions}
    />
  );

  // return (
  //   <div className="space-y-1">
  //     <OptionalLabel required={required} name="attendees" label="Attendees" />
  //
  //     {attendees
  //       .map((item, index) => (
  //         <SelectCreate
  //           key={index}
  //           type="attendees"
  //           options={filteredSuggestions}
  //           value={item}
  //           onChange={(value) => push(value)}
  //           className={defaultInputStyles}
  //         />
  //       ))
  //       .reverse()}
  //   </div>
  // );
};

export default AttendeesList;
