import { mdiClipboardAccountOutline, mdiClipboardListOutline, mdiFileFindOutline, mdiFolderOpen, mdiFormTextbox, mdiHome } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { Link } from 'react-router-dom';
import usePermissions from 'Support/hooks/usePermissions';
import useRole from 'Support/hooks/useRole';
import route from 'Support/route';

const BottomNav = () => {
  const permissions = usePermissions();

  const bottomNavLinks = [
    {
      text: 'TA',
      route: route('job.task-analysis.add'),
      icon: mdiFileFindOutline,
    },
    {
      text: 'Add Form',
      route: route('reports.select-form-template'),
      icon: mdiFormTextbox,
      filter: () => permissions.show_reports_pwa,
    },
    {
      text: 'Home',
      route: route('dashboard'),
      icon: mdiHome,
    },
    {
      text: 'My Tasks',
      route: route('tasks'),
      icon: mdiClipboardAccountOutline,
    },
    {
      text: 'Jobs',
      route: route('jobs'),
      icon: mdiClipboardListOutline,
    },
  ].filter((item) => typeof item.filter !== 'function' || item.filter());

  const BottomNavButton = ({ bottomNav }) => (
    <Link to={bottomNav.route} className="flex w-full flex-col place-items-center bg-white p-2 text-primary">
      <Icon path={bottomNav.icon} size={1.5} />
      <p className="whitespace-nowrap text-center text-xs">{bottomNav.text}</p>
    </Link>
  );

  return (
    <div className="w-full bg-white px-1 shadow-2xl">
      <div className=" grid grid-cols-5">
        {bottomNavLinks.map((bottomNav, index) =>
          bottomNav.text !== 'Home' ? (
            <BottomNavButton key={index} bottomNav={bottomNav} />
          ) : (
            <div className="relative" key={index}>
              <div className="absolute -top-7 left-1/2 -ml-7">
                <Link
                  to={bottomNav.route}
                  className="flex h-16 w-16 w-full flex-col place-items-center rounded-full bg-white p-2 text-primary shadow-lg"
                >
                  <Icon path={bottomNav.icon} size={2} />
                </Link>
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default BottomNav;
