import React from 'react';

const Breadcrumbs = ({ path, setPath }) => {
  const parts = path.split('/');

  return (
    <h3 className="mb-6 text-xl">
      <span>
        <button onClick={() => setPath('')}>Home</button>
        &nbsp;
      </span>

      {parts.map((part, index) => (
        <span key={index}>
          / &nbsp;
          <button onClick={() => setPath(parts.slice(0, index + 1).join('/'))}>{part}</button>
          &nbsp;
        </span>
      ))}
    </h3>
  );
};

export default Breadcrumbs;
