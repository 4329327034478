import { mdiArrowLeft, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import FieldTransition from 'Components/FieldTransition';
import FieldWrapper from 'Components/Forms/FieldWrapper';
import FormWrapper from 'Components/Forms/FormWrapper';
import SelectField from 'Components/Forms/SelectField';
import SwitchField from 'Components/Forms/SwitchField';
import TextField from 'Components/Forms/TextField';
import SyncSpinner from 'Components/SyncSpinner';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import useFormikSubmit from 'Support/hooks/useFormikSubmit';
import useOrganization from 'Support/hooks/useOrganization';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

export const entityTypeOptions = [
  {
    value: null,
    label: '---',
  },
  {
    value: 'App\\Models\\Job',
    label: 'Job',
  },
  {
    value: 'App\\Models\\Report',
    label: 'Form',
  },

  {
    value: 'App\\Models\\ViewLog',
    label: 'View Log',
  },
];

export const recurringOptions = [
  {
    value: 'now',
    label: 'Now',
  },
  {
    value: 'due_at',
    label: 'Due At',
  },
  {
    value: 'at_closed',
    label: 'When Task Closes',
  },
];

const AddTask = () => {
  const history = useHistory();

  const isOnline = useOnlineStatus();
  const [entityOptions, setEntityOptions] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [reports, setReports] = useState([]);
  const [users, setUsers] = useState([]);
  const [syncing, setSyncing] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const sync = async () => {
    if (!syncing) {
      setSyncing(true);

      setJobs(await api.syncJobs());
      setUsers(await api.syncUsers());
      setReports(await api.syncReports());

      setSyncing(false);
    }
  };

  useEffect(async () => {
    setJobs((await valueStore.getArray(`jobs`)) || []);
    setTasks((await valueStore.getArray(`tasks`)) || []);
    setUsers((await valueStore.getArray(`users`)) || []);
    setReports((await valueStore.getArray(`reports`)) || []);
  }, []);

  usePageTitle(`Add Task`);

  const formikProps = {
    initialValues: {
      assignee_id: '',
      due_at: '',
      name: '',
      description: '',
      entity_type: null,
      entity_id: null,
      is_recurring: false,
      at_closed: false,
      recurring_interval: 0,
      recurrence_type: '',
      include_due_at: false,
      days_for_next_due_at: 0,
    },
  };

  const validationSchema = Yup.object().shape({
    assignee_id: Yup.string().required('Please select an Assignee'),

    name: Yup.string().required('Please enter a name'),

    description: Yup.string().required('Please enter a description'),

    recurring_interval: Yup.mixed().when(['is_recurring', 'at_closed'], {
      is: (is_recurring, at_closed) => is_recurring && !at_closed,
      then: Yup.number().moreThan(0, 'Please set a valid Number of days').required('Please select a number of days before recurring'),
    }),

    recurrence_type: Yup.mixed().when(['is_recurring', 'at_closed'], {
      is: (is_recurring, at_closed) => is_recurring && !at_closed,
      then: Yup.string().required('Please select a type of Recurrence'),
    }),

    due_at: Yup.mixed().when('recurrence_type', {
      is: (recurrence_type) => recurrence_type === 'due_at',
      then: Yup.string().required('Please set a Due at date'),
    }),

    days_for_next_due_at: Yup.mixed().when(['is_recurring', 'at_closed', 'include_due_at'], {
      is: (is_recurring, at_closed, include_due_at) => is_recurring && !at_closed && include_due_at,
      then: Yup.number()
        .moreThan(0, 'Please select a valid number of days')
        .required('please set the amount of days after repeated task creation you want to make the new due at date'),
    }),
  });

  const onSubmit = useFormikSubmit(async (values) => {
    const { organization_id } = useOrganization();
    setSubmitting(true);
    tasks.push({
      ...values,
      temp_id: uuidv4(),
      organization_id: organization_id,
      new: true,
    });

    setTasks(tasks);

    await valueStore.set(`tasks`, tasks);

    history.push(route('tasks'));
  });

  useEffect(async () => {
    if (isOnline) {
      await sync();
    }
  }, [JSON.stringify(tasks)]);

  return (
    <div className="m-4">
      <SyncSpinner loading={submitting} />

      <SecondaryButton as={Link} to={route('tasks')} className="mb-12 w-full py-2">
        <Icon path={mdiArrowLeft} size={1} className="mr-1" /> Cancel
      </SecondaryButton>

      <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ values: { entity_type, is_recurring, due_at, at_closed, recurrence_type, include_due_at }, setFieldValue, errors }) => {
          useEffect(() => {
            if (entity_type === entityTypeOptions[1].value) {
              setEntityOptions(jobs);
            }
            if (entity_type === entityTypeOptions[2].value) {
              setEntityOptions(reports);
            }
          }, [entity_type]);

          return (
            <Form>
              <FormWrapper className="rounded-lg bg-white shadow-md">
                <FieldWrapper>
                  <SelectField name="assignee_id" label="Assignee" options={users} valueKey="id" labelKey="name" isRequired={true} />
                </FieldWrapper>
                <FieldWrapper>
                  <SelectField name="entity_type" label="Link to" options={entityTypeOptions} />
                  <FieldTransition show={entityOptions.length}>
                    <div className="mt-4">
                      <SelectField name="entity_id" label="Job" options={entityOptions} valueKey="id" labelKey="name" />
                    </div>
                  </FieldTransition>
                </FieldWrapper>

                <FieldWrapper>
                  <TextField name="due_at" type="datetime-local" isRequired={recurrence_type === 'due_at'} />
                </FieldWrapper>

                <FieldWrapper>
                  <TextField name="name" isRequired={true} />
                </FieldWrapper>

                <FieldWrapper>
                  <TextField name="description" isRequired={true} />
                </FieldWrapper>

                <FieldWrapper>
                  <SwitchField name="is_recurring" label="Would you like to make this task Recurring?" yesText="Yes" noText="No" />
                </FieldWrapper>

                {is_recurring && (
                  <>
                    <FieldWrapper>
                      <SwitchField name="at_closed" label="Would you like this task to reoccur on status close?" />
                    </FieldWrapper>
                    {!at_closed && (
                      <>
                        <p className="flex items-center text-sm text-gray-800">
                          <span className="mr-3">Repeat</span>

                          <FieldWrapper>
                            <TextField name="recurring_interval" type="number" className="w-24" />
                          </FieldWrapper>
                          <span className="ml-1 mr-3 text-red-500">*</span>

                          <span className="mr-3">days from</span>
                          <FieldWrapper className="w-48">
                            <SelectField
                              name="recurrence_type"
                              options={Object.values(recurringOptions).filter((option) => {
                                return option.value !== 'at_closed';
                              })}
                              valueKey="value"
                              labelKey="label"
                            />
                          </FieldWrapper>
                          <span className="ml-1 mr-3 text-red-500">*</span>
                        </p>
                        {recurrence_type === 'due_at' && (
                          <>
                            <FieldWrapper>
                              <SwitchField name="include_due_at" label="Would you like Your due date in repeated tasks?" />
                            </FieldWrapper>
                            {include_due_at && (
                              <FieldWrapper>
                                <TextField
                                  name="days_for_next_due_at"
                                  label="Amount of days after repeated task creation you want to make the new due at date"
                                  type="number"
                                />
                              </FieldWrapper>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                <FieldWrapper className="mt-6 grid grid-cols-2 gap-4">
                  <div />
                  <PrimaryButton type="submit" className="w-full" disabled={Object.values(errors).length}>
                    Submit <Icon path={mdiChevronRight} size={1} />
                  </PrimaryButton>
                </FieldWrapper>
              </FormWrapper>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddTask;
