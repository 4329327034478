import { format, parseJSON } from 'date-fns';
import React from 'react';

const ActionTextField = ({ label, text }) => (
  <div className="flex space-x-2 py-2 pl-2 sm:border-t sm:border-gray-200">
    <p className="text-sm font-semibold text-gray-500">{label}</p>
    <p>{text}</p>
  </div>
);

const PreviousAction = ({ action, className }) => (
  <div className={className}>
    <hr className="my-4" />
    <h3 className="text-md font-semibold text-black">
      {action.task ? 'Task - ' : 'Email'}
      {action.task?.name}
    </h3>

    {action.task && (
      <>
        <ActionTextField
          label="Created at: "
          text={action.task?.created_at ? format(parseJSON(action.task?.created_at), 'dd/MM/yyyy hh:mmaaa') : 'No Created date'}
        />
        <ActionTextField label="Action: " text={action.task?.description} />
        <ActionTextField label="Assigned to: " text={action.task?.assignee?.name} />
        <ActionTextField label="Due at: " text={action.task?.due_at ? format(parseJSON(action.task?.due_at), 'dd/MM/yyyy hh:mmaaa') : 'No due date'} />
        <ActionTextField label="Status" text={action.task?.status?.label} />
        <ActionTextField
          label="Completed at:"
          text={action.task?.closed_at ? format(parseJSON(action.task?.closed_at), 'dd/MM/yyyy hh:mmaaa') : 'No due date'}
        />

        <p className="text-sm font-semibold text-black">Additional Notes:</p>
        {action.task?.comments && (
          <>
            {action.task.comments.map((comment) => (
              <ActionTextField label={comment.user?.name + ': '} text={comment.comment} />
            ))}
          </>
        )}
        {/*Backlog added CommentBox notes to November 8, 2022*/}
        {/*<CommentBox task_id={action.task}/>*/}
      </>
    )}
  </div>
);

export default PreviousAction;
