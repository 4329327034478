import { AuthenticatedRoute, GuestRoute, Route } from 'Components/Route';
import React from 'react';
import { BrowserRouter as Router, Switch as RouterSwitch } from 'react-router-dom';
import routes from 'routes';

const App = () => (
  <Router>
    <RouterSwitch>
      {routes.map(({ name, auth, ...props }) => {
        const RouteComponent = auth === true ? AuthenticatedRoute : auth === false ? GuestRoute : Route;
        return <RouteComponent key={name} {...props} name={name} />;
      })}
    </RouterSwitch>
  </Router>
);

export default App;
