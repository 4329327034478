import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useEffect, useState } from 'react';
import imageStore from 'Services/imageStore';
import theme from 'tailwind-theme';

const StoredImage = ({ image, deleteImage }) => {
  const [url, setUrl] = useState(false);
  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    let localUrlReference;
    imageStore.get(image).then((file) => {
      if (mounted) {
        localUrlReference = URL.createObjectURL(file);
        setUrl(localUrlReference);
      }
    });

    return () => {
      setMounted(false);
      if (localUrlReference) {
        URL.revokeObjectURL(localUrlReference);
      }
    };
  }, [image]);

  return !url ? (
    <>Loading</>
  ) : (
    <div className="relative">
      <div className="absolute right-2 top-0 -mt-3">
        <button
          type="button"
          aria-label="Close menu"
          className="rounded-full bg-white text-red-500 transition duration-150 ease-in-out hover:text-white"
          onClick={deleteImage}
        >
          <Icon path={mdiClose} size={theme.fontSize['2xl'][0]} />
        </button>
      </div>
      <img src={url} />
    </div>
  );
};

export default StoredImage;
