import auth from 'Services/auth';

const useOrganization = () => {
  return {
    organization_id: auth.getOrganization(),
    organization_name: auth.getOrganizationName(),
    organization_uses_sssp: auth.getOrganizationUsesSSSP(),
    previous_organization_name: auth.getPreviousOrganizationName(),
  };
};

export default useOrganization;
