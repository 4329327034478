import cls from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import auth from 'Services/auth';
import route from 'Support/route';

const Tabs = ({ job, active }) => {
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    // you don't want to show these if you don't have an ID (No ID means no DB entry)
    if (job?.id) {
      setTabs(
        [
          {
            key: 'ta',
            name: 'Task Analysis',
            url: route('job', { id: job.id }),
          },
          {
            key: 'visitor_logs',
            name: 'Visitor Logs',
            url: route('visitor-logs', { id: job.id }),
          },
          auth.getOrganizationUsesSSSP()
            ? {
                key: 'sssp',
                name: 'SSSP',
                url: route('job.sssp', { id: job.id }),
              }
            : null,
          {
            key: 'forms',
            name: 'Forms',
            url: route('job.reports', { id: job.id }),
          },
        ].filter((t) => t !== null),
      );
    }
  }, [JSON.stringify(job), auth.getOrganizationUsesSSSP()]);

  return (
    <div className="z-10 grid grid-cols-4">
      {tabs.map((tab, index) =>
        tab.external ? (
          <a key={tab.key} target="_blank" href={tab.url} className={cls('p-4', active === tab.key ? 'bg-white' : 'border border-gray-200')}>
            {tab.name}
          </a>
        ) : (
          <Link key={tab.key} to={tab.url} className={cls('p-4', active === tab.key ? 'bg-white' : 'border border-gray-200')}>
            {tab.name}
          </Link>
        ),
      )}
    </div>
  );
};

export default Tabs;
