import useOnlineStatus from '@rehooks/online-status';
import MultiField from 'Components/Forms/MultiField';
import { useEffect, useMemo, useState } from 'react';
import api from 'Services/api';
import valueStore from 'Services/valueStore';

const PPEList = ({ ppe: initialPPE, push, insert, required = false }) => {
  const isOnline = useOnlineStatus();
  const [suggestions, setSuggestions] = useState([]);

  const sync = () => {
    const getPPE = async () => await api.fetchPPE();

    getPPE().then(async (ppe) => {
      const values = ppe.map((ppe) => ppe.description);
      const ppeArray = initialPPE.map((ppe) => ppe.description);
      const mergedArray = [...values, ...ppeArray];
      setSuggestions(mergedArray);
      await valueStore.set(`ppe`, values);
    });
  };

  const filteredSuggestions = useMemo(
    () => suggestions.filter((suggestion) => !initialPPE.map((ppe) => ppe.description.toLowerCase()).includes(suggestion.toLowerCase())),
    [initialPPE, suggestions],
  );

  useEffect(async () => {
    if (isOnline) {
      sync();
    } else {
      await valueStore.getArray(`ppe`);
    }
  }, []);

  return (
    <MultiField
      name="ppe"
      required={required}
      values={initialPPE}
      insert={insert}
      push={push}
      label="What PPE is required?"
      placeholder="Add new PPE"
      suggestions={filteredSuggestions}
    />
  );
};

export default PPEList;
