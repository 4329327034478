import { mdiCalendar, mdiSync } from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import * as Sentry from '@sentry/react';
import cls from 'classnames';
import ListItem from 'Components/ListItem';
import ListItemWrapper from 'Components/ListItemWrapper';
import SyncSpinner from 'Components/SyncSpinner';
import { format, parseJSON } from 'date-fns';
import Tabs from 'Pages/Jobs/Tabs';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import { JobContext } from 'Support/Contexts/JobContext';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';

const JobReports = () => {
  const { id } = useParams();
  const { currentJob, updateCurrentJobId, refresh } = useContext(JobContext);
  const [syncing, setSyncing] = useState(false);
  const [reports, setReports] = useState([]);
  const [search, setSearch] = useState('');
  const [searchedReports, setSearchedReports] = useState([]);
  const [askAnExpertForm, setAskAnExpertForm] = useState(null);
  const [incidentReportForm, setIncidentReportForm] = useState(null);

  const sync = async () => {
    if (isOnline && !syncing) {
      setSyncing(true);
      await api.syncJobs();
      const reportQuery = await api.syncReports();
      const forms = await api.syncForms();
      setAskAnExpertForm(forms.find((form) => form.special_use === 'ask-an-expert')?.id);
      setIncidentReportForm(forms.find((form) => form.special_use === 'incident-notification')?.id);
      setReports(reportQuery);
      setSearchedReports(reportQuery);
    } else if (!reports.length) {
      let taskAnalysisQuery = (await valueStore.getArray(`reports`)) || [];
      let newReportsQuery = taskAnalysisQuery.filter((ta) => !ta.incomplete);

      setReports(newReportsQuery);
      setSearchedReports(newReportsQuery);
    }

    setSyncing(false);
  };

  useEffect(() => {
    if (search) {
      Sentry.addBreadcrumb({
        category: 'searchedReports',
        level: 'debug',
        message: 'Loading reports from reports state filtering',
      });
      setSearchedReports(reports.filter((report) => report.name?.toLowerCase().includes(search.toLowerCase())));
    } else {
      setSearchedReports(reports);
    }
  }, [search]);

  useEffect(() => {
    updateCurrentJobId(id);
    refresh();
  }, [id]);

  useEffect(async () => {
    await sync();
  }, []);

  usePageTitle(currentJob?.name);

  const isOnline = useOnlineStatus();

  return (
    <>
      <Tabs job={currentJob} active="forms" />
      <div className="relative bg-white p-4">
        <SyncSpinner loading={syncing} />
        <ListItemWrapper searchName="Forms" setSearch={setSearch} searchDisabled={syncing}>
          {searchedReports?.map((report, index) =>
            parseInt(report.task_job_id) === parseInt(id) && ![askAnExpertForm, incidentReportForm].includes(report.form_template_id) ? (
              <ListItem
                key={index}
                mainTitle={
                  <div className="flex items-center gap-2">
                    {(report.new || report.updated) && (
                      <div className={cls(report.updated ? 'bg-orange-500' : 'bg-red-500', 'rounded-2xl p-1')}>
                        <Icon path={mdiSync} size={0.7} className="text-white" />
                      </div>
                    )}
                    {report.name}
                  </div>
                }
                subTitles={[
                  <>
                    <div className="mb-3">
                      <p className="text-sm text-gray-900">
                        <span className="font-semibold text-gray-500">ID: </span>
                        {report.id}
                      </p>
                      {report.description && (
                        <p className="text-sm text-gray-900">
                          <span className="font-semibold text-gray-500">Description: </span>
                          {report.description}
                        </p>
                      )}
                    </div>
                    <div className="flex space-x-1 text-xs text-gray-600">
                      <Icon path={mdiCalendar} size={0.6} />
                      <p>
                        {report.created_at === 'now' || !report.created_at
                          ? format(Date.now(), 'yyyy/MM/dd hh:mm aaa')
                          : format(parseJSON(report.created_at), 'yyyy/MM/dd hh:mm aaa')}
                      </p>
                    </div>
                  </>,
                ]}
                as={Link}
                to={report.id || report.temp_id ? route('reports.edit', { id: report.id || report.temp_id }) : '#'}
                status={report.id ? 'New' : 'To be synced'}
              />
            ) : null,
          )}
        </ListItemWrapper>
      </div>
    </>
  );
};

export default JobReports;
