import Icon from '@mdi/react';
import cls from 'classnames';
import React, { forwardRef } from 'react';

const CompactButton = ({ children, className = '', startIcon, endIcon, as = 'button', ...otherProps }, ref) => {
  const Element = as;

  if (!className.includes('h-')) {
    className += ' h-6';
  }

  return (
    <Element
      ref={ref}
      type="button"
      className={cls(
        'inline-flex items-center justify-center bg-white text-black transition duration-150 ease-in-out hover:text-primary',
        'rounded px-2 text-base',
        'hover:opacity-90 focus:outline-none',
        'disabled:cursor-default disabled:opacity-50',
        className,
      )}
      {...otherProps}
    >
      {startIcon ? <Icon path={startIcon} size={1} /> : null}
      {children}
      {endIcon ? <Icon path={endIcon} size={1} /> : null}
    </Element>
  );
};

export default forwardRef(CompactButton);
