import OptionalLabel from 'Components/Forms/OptionalLabel';
import { ErrorMessage, Field } from 'formik';
import React from 'react';

export const defaultInputStyles =
  'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out text-sm sm:leading-5';

const TextField = ({ name, label = null, type = 'text', isRequired = false, ...props }) => (
  <div>
    <OptionalLabel required={props.required || isRequired} name={name} label={label} />

    <div className="mt-1">
      <Field className={defaultInputStyles} type={type} name={name} {...props} />
      <ErrorMessage name={name} component="div" className="font-content text-xs italic text-red-500" />
    </div>
  </div>
);

export default TextField;
