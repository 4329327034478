import cls from 'classnames';
import React, { forwardRef } from 'react';

const PrimaryButton = ({ children, className = '', as = 'button', ...props }, ref) => {
  const Element = as;

  if (!className.match(/(?:^|\s)h-\d+(?:$|\s)/)) {
    className += ' h-11';
  }

  return (
    <Element
      ref={ref}
      className={cls(
        'inline-flex items-center justify-center bg-primary text-white transition duration-150 ease-in-out',
        'rounded px-6 text-base',
        'hover:opacity-90 focus:outline-none',
        'disabled:cursor-default disabled:opacity-50',
        className,
      )}
      {...props}
    >
      {children}
    </Element>
  );
};

export default forwardRef(PrimaryButton);
