import useOnlineStatus from '@rehooks/online-status';
import MultiField from 'Components/Forms/MultiField';
import { useEffect, useState } from 'react';
import api from 'Services/api';
import valueStore from 'Services/valueStore';

const TrainingList = ({ training: initialTraining, push, required = false, insert }) => {
  const isOnline = useOnlineStatus();
  const [suggestions, setSuggestions] = useState([]);

  const sync = () => {
    const getTraining = async () => await api.fetchTraining();

    getTraining().then(async (training) => {
      const values = training.map((training) => training.description);
      const trainingArray = initialTraining.map((training) => training.description);
      const mergedArray = [...trainingArray, ...values];
      setSuggestions(mergedArray);
      await valueStore.set(`training`, values);
    });
  };

  useEffect(async () => {
    if (isOnline) {
      sync();
    } else {
      await valueStore.getArray(`training`);
    }
  }, []);

  return (
    <MultiField
      name="training"
      values={initialTraining}
      push={push}
      insert={insert}
      label="What Training is needed?"
      placeholder="Add new Training"
      required={required}
      suggestions={suggestions}
    />
  );
};

export default TrainingList;
