import { mdiArrowLeft, mdiMessage, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import useOnlineStatus from '@rehooks/online-status';
import cls from 'classnames';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import FormWrapper from 'Components/Forms/FormWrapper';
import SyncSpinner from 'Components/SyncSpinner';
import { Form, Formik } from 'formik';
import FormField from 'Pages/Reports/FormField';
import PreviousAction from 'Pages/Reports/PreviousAction';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import useFormikSubmit from 'Support/hooks/useFormikSubmit';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';
import * as Yup from 'yup';

const EditReport = () => {
  const { id } = useParams();
  const history = useHistory();

  const isOnline = useOnlineStatus();
  const [reports, setReports] = useState([]);
  const [actions, setActions] = useState([]);
  const [currentReport, setCurrentReport] = useState();
  const [currentReportIndex, setCurrentReportIndex] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [formikProps, setFormikProps] = useState();
  const [loading, setLoading] = useState(true);
  const [showComments, setShowComments] = useState(false);
  const [newComment, setNewComment] = useState('');

  useEffect(async () => {
    setReports(await valueStore.getArray(`reports`));
    if (isOnline) {
      sync();
    }
  }, []);

  usePageTitle(`View Form`);

  const validationSchema = Yup.object().shape({});

  const onSubmit = useFormikSubmit(async (values) => {
    setSubmitting(true);

    reports[currentReportIndex] = {
      name: values.name || '',
      form: values.form || [],
      updated: true,
    };

    setReports(reports);

    await valueStore.set(`reports`, reports);

    history.push(route('reports'));
  });

  const addComment = async (event) => {
    event.preventDefault();
    if (newComment !== '') {
      if (!currentReport.comments) {
        currentReport.comments = [];
      }

      currentReport.comments.push({
        comment: newComment,
        new: true,
      });

      setSubmitting(true);

      reports[currentReportIndex] = {
        ...currentReport,
        updated: true,
      };

      setReports(reports);

      await valueStore.set(`reports`, reports);

      setSubmitting(false);

      setNewComment('');
    }
  };

  useEffect(async () => {
    const findCurrentReport = reports?.find((report) => {
      if (report.id) {
        return report.id === parseInt(id);
      }
      return report.temp_id === id;
    });
    setCurrentReport(findCurrentReport);
    setCurrentReportIndex(reports.indexOf(findCurrentReport));
  }, [JSON.stringify(reports)]);

  const sync = () => {
    const getHazards = async () => await api.fetchHazards();
    getHazards().then(async (hazards) => {
      await valueStore.set(`hazards`, hazards);
    });
  };

  useEffect(async () => {
    if (currentReport?.name && !formikProps?.initialValues) {
      setFormikProps({
        initialValues: {
          id: currentReport.id,
          temp_id: currentReport.temp_id,
          name: currentReport.name,
          form: currentReport.form,
        },
      });
      setActions(currentReport.actions);
      setLoading(false);
    }
  }, [JSON.stringify(currentReport)]);

  return (
    <div className="m-4">
      <SyncSpinner loading={submitting} />

      <div className="flex justify-between">
        <SecondaryButton as={Link} to={route('reports')} className="mb-12 py-2">
          <Icon path={mdiArrowLeft} size={1} className="mr-1" /> Back
        </SecondaryButton>
        <PrimaryButton className="relative mb-12" type="button" onClick={() => setShowComments(!showComments)}>
          <Icon path={mdiMessage} className="h-4 w-4" />
          {currentReport?.comments?.length > 0 && (
            <span className="absolute -right-2 -top-2 rounded-full bg-red-600 px-2">{currentReport.comments.length}</span>
          )}
        </PrimaryButton>
      </div>

      {showComments && (
        <>
          <form onSubmit={addComment} className="flex gap-4">
            <textarea
              placeholder="Add comment"
              onChange={(e) => setNewComment(e.target.value)}
              className={cls(
                'block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:outline-none',
                'focus:shadow-outline-blue transition duration-150 ease-in-out focus:border-blue-300 sm:text-sm sm:leading-5',
              )}
            >
              {newComment}
            </textarea>
            <PrimaryButton>
              <Icon path={mdiPlus} size={1} />
              Add
            </PrimaryButton>
          </form>

          <div className="mb-20">
            {(currentReport?.comments || []).map((comment, index) => (
              <div key={index} className="mt-2 border-t pt-2 text-sm text-gray-700 first:border-0">
                {comment.comment}
                <div className="text-right text-gray-500">{comment.author?.name || 'Newly added'}</div>
              </div>
            ))}
          </div>
        </>
      )}

      {!loading && (
        <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
          {({ values, setFieldValue, errors }) => (
            <Form>
              <FormWrapper className="rounded-lg bg-white shadow-md">
                <div className="space-y-4">
                  <div className="space-y-8">
                    <h3 className="text-lg font-bold text-gray-900">{currentReport?.name}</h3>
                    <p className="text-sm text-gray-900">
                      <span className="font-semibold text-gray-500">Job: </span>
                      {currentReport?.job?.name}
                    </p>
                    {values.form.map((field, index) => (
                      <FormField key={index} index={index} values={values.form} setFieldValue={setFieldValue} />
                    ))}
                    <hr />
                    <div className="mt-4">
                      <h3 className="my-2 text-lg font-bold tracking-wide text-gray-900">Corrective Actions</h3>
                      {actions?.map((action, index) => (
                        <PreviousAction className="sm:border-t sm:border-gray-200 " key={index} action={action} />
                      ))}
                    </div>
                  </div>
                </div>
              </FormWrapper>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditReport;
