import { mdiAlert, mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import PopUp from 'Components/PopUp';
import React, { useState } from 'react';
import valueStore from 'Services/valueStore';

export const removeDataFromLocalStorage = async () => {
  await Promise.all([valueStore.delete(`jobs`), valueStore.delete(`tasks`), valueStore.delete(`taskAnalysis`), valueStore.delete(`reports`)]);
};

export const FailedSyncPopUp = ({ isOpen, setOpen, onSubmit }) => {
  const [disabled, setDisabled] = useState(false);
  return (
    <PopUp setOpen={setOpen} isOpen={isOpen} canClose={false} disabled={true} height="h-100">
      <div className="ml-3 mt-4 flex">
        <Icon path={mdiAlert} size={1} className="text-red-500" />
        <h4 className="px-3 pb-5 font-semibold tracking-wider text-gray-900" id="projects-headline">
          Failed to Sync all Data
        </h4>
      </div>
      <hr />
      <div className="flex h-full flex-col justify-between px-3">
        <div className="flex flex-col divide-y divide-gray-200 pb-4 pt-3">
          <p>
            We have failed to sync data from previous organization, so we sent that data to the server. <br />
            Please contact {window.clientName} if anything important is missing we can generate it for you.
            <br />
            Please note the time and date of the issue for reference
          </p>
        </div>
        <div className="mb-4 flex justify-between space-x-5">
          <PrimaryButton
            type="button"
            disabled={disabled}
            onClick={async () => {
              setDisabled(true);
              await removeDataFromLocalStorage();
              onSubmit();
            }}
            className="h-full py-2"
          >
            {disabled && <Icon path={mdiLoading} size={1} className="mr-2 animate-spin text-white" />}
            Ok
          </PrimaryButton>
        </div>
      </div>
    </PopUp>
  );
};
