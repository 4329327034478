import { Transition } from '@headlessui/react';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import cls from 'classnames';
import React from 'react';
import theme from 'tailwind-theme';

const BackgroundOverlay = ({ show, ...props }) => (
  <Transition.Child
    as="div"
    {...props}
    enter="ease-in-out duration-200"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in-out duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
  />
);

const PanelWrapper = ({ ...props }) => <div className=" inset-y-0 ml-auto mr-auto mt-28 flex w-80 max-w-screen-9/10" {...props} />;

const Panel = ({ show, ...props }) => (
  <Transition.Child
    as="div"
    {...props}
    enter="transform transition ease-in-out duration-300"
    enterFrom="translate-x-full"
    enterTo="translate-x-0"
    leave="transform transition ease-in-out duration-300"
    leaveFrom="translate-x-0"
    leaveTo="translate-x-full"
    className="relative w-screen max-w-md"
  />
);

const CloseButton = ({ onClick }) => (
  <div className="absolute right-0 top-0 -ml-12 flex pl-4 pr-2 pt-4">
    <button aria-label="Close menu" className="text-gray-400 transition duration-150 ease-in-out hover:text-white" onClick={onClick}>
      <Icon path={mdiClose} size={theme.fontSize['2xl'][0]} />
    </button>
  </div>
);

const PopUp = ({ isOpen, setOpen, canClose = true, children, disabled, height = 'h-72' }) => {
  const closeNav = () => setOpen(false);

  return (
    <Transition show={isOpen} className="fixed inset-0 z-10 overflow-hidden">
      <div className="absolute inset-0 overflow-hidden">
        <BackgroundOverlay
          show={isOpen}
          onClick={() => {
            canClose && !disabled ? closeNav() : null;
          }}
        />

        <PanelWrapper>
          <Panel show={isOpen}>
            {canClose && !disabled ? <CloseButton onClick={closeNav} /> : null}
            <div className={cls('ml-auto mr-auto flex flex-col overflow-y-auto rounded-2xl bg-white shadow-xl', height)}>{children}</div>
          </Panel>
        </PanelWrapper>
      </div>
    </Transition>
  );
};

export default PopUp;
