import cls from 'classnames';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import api from 'Services/api';
import valueStore from 'Services/valueStore';
import useFormikSubmit from 'Support/hooks/useFormikSubmit';
import route from 'Support/route';
import * as Yup from 'yup';

const ForgotPasswordForm = (props) => {
  const history = useHistory();

  const formikProps = {
    initialValues: {
      email: '',
    },
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Please provide your e-mail address').email('Please provide a valid e-email address'),
  });

  const onSubmit = useFormikSubmit(async (values) => {
    const exists = await api.checkUser(values);
    if (exists) {
      await valueStore.set(`reset-email`, values.email);
      history.push(route('reset.code'));
    }
  });

  return (
    <Formik {...formikProps} onSubmit={onSubmit} validationSchema={validationSchema}>
      <Form {...props}>
        <div>
          <label htmlFor="email" className="block text-sm font-medium leading-5 text-gray-700">
            Email address
          </label>
          <div className="mt-1">
            <Field
              className={cls(
                'block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm',
                'focus:shadow-outline-blue transition duration-150 ease-in-out focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5',
              )}
              type="email"
              name="email"
              placeholder="jane.doe@email.com"
            />
            <ErrorMessage name="email" component="div" className="font-content text-xs italic text-red-500" />
          </div>
        </div>

        <div className="mt-6">
          <span className="block w-full">
            <PrimaryButton type="submit" className="w-full">
              Request password reset
            </PrimaryButton>
            <Link to={route('login')} className="mt-6 block text-center text-sm text-primary">
              Login
            </Link>
          </span>
        </div>
      </Form>
    </Formik>
  );
};

export default ForgotPasswordForm;
