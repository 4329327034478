import { mdiArrowLeft, mdiCheck } from '@mdi/js';
import Icon from '@mdi/react';
import SecondaryButton from 'Components/Buttons/SecondaryButton';
import FieldWrapper from 'Components/Forms/FieldWrapper';
import ImageDisplay from 'Components/Forms/ImageDisplay';
import ViewContent from 'Components/Forms/ViewContent';
import ViewLabel from 'Components/Forms/ViewLabel';
import SyncSpinner from 'Components/SyncSpinner';
import { format, parseJSON } from 'date-fns';
import { riskRatingValues } from 'Pages/Jobs/RiskDropdown';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import valueStore from 'Services/valueStore';
import usePageTitle from 'Support/hooks/usePageTitle';
import route from 'Support/route';

const ViewTaskAnalysis = () => {
  const { id } = useParams();

  const [currentTaskAnalysis, setCurrentTaskAnalysis] = useState();
  const [taskAnalysis, setTaskAnalysis] = useState([]);
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);

  const currentJob = useMemo(() => {
    if (taskAnalysis.length === 0) return;
    const taskAnalysisJobID = taskAnalysis?.find((ta) => {
      if (ta.id) {
        return ta.id === parseInt(id);
      }
      return ta.temp_id === id;
    })?.job_id;
    return jobs?.find((job) => job.id === taskAnalysisJobID);
  }, [jobs, taskAnalysis]);

  useEffect(async () => {
    setTaskAnalysis((await valueStore.getArray(`taskAnalysis`)) || []);
    setJobs(await valueStore.getArray('jobs'));
  }, []);

  usePageTitle(`View Task Analysis`);

  useEffect(async () => {
    if (taskAnalysis.length === 0) return;
    setCurrentTaskAnalysis(
      taskAnalysis?.find((ta) => {
        if (ta.id) {
          return ta.id === parseInt(id);
        }
        return ta.temp_id === id;
      }),
    );
  }, [JSON.stringify(taskAnalysis)]);

  useEffect(async () => {
    if (currentTaskAnalysis?.id || currentTaskAnalysis?.temp_id) {
      setLoading(false);
    }
  }, [JSON.stringify(currentTaskAnalysis)]);

  return (
    <div className="m-4">
      <SyncSpinner loading={loading} />

      {currentTaskAnalysis?.job_id && (
        <SecondaryButton as={Link} to={route('job', { id: currentTaskAnalysis?.job_id })} className="relative z-10 mb-12 w-full py-2">
          <Icon path={mdiArrowLeft} size={1} className="mr-1" /> Cancel
        </SecondaryButton>
      )}

      {!loading && (
        <div className="-m-4 rounded-lg bg-white p-4 shadow-md">
          <h3 className="text-lg font-bold text-gray-900">{currentTaskAnalysis.activity}</h3>
          <p className="text-md font-semibold text-gray-900">{currentJob?.name}</p>
          <p className="text-sm text-gray-900">
            {currentTaskAnalysis.updated_at === 'now' || !currentTaskAnalysis.updated_at
              ? format(Date.now(), 'dd/MM/yyyy hh:mmaaa')
              : format(parseJSON(currentTaskAnalysis.updated_at), 'dd/MM/yyyy hh:mmaaa')}
          </p>

          <p className="text-sm text-gray-900">{currentTaskAnalysis.location}</p>

          <hr className="mt-4 border-gray-100" />

          <div className="space-y-4">
            <FieldWrapper>
              <ViewLabel text="Attendees" />
              <ViewContent
                className="col-span-2"
                text={
                  <ul className="ml-4 list-outside list-disc">
                    {currentTaskAnalysis.attendees.map((attendee, index) => (
                      <li key={index} className="ml-1 text-gray-500">
                        <span className="text-gray-900">{attendee}</span>
                      </li>
                    ))}
                  </ul>
                }
              />
            </FieldWrapper>

            <FieldWrapper>
              <ViewLabel text="What steps will be used?" />
              <ViewContent
                className="col-span-2"
                text={
                  <ol className="ml-4 list-outside list-decimal">
                    {currentTaskAnalysis.steps.map((ppe, index) => (
                      <li key={index} className="ml-1 text-gray-500">
                        <span className="text-gray-900">{ppe.description}</span>
                      </li>
                    ))}
                  </ol>
                }
              />
            </FieldWrapper>

            <FieldWrapper>
              <ViewLabel text="What training is needed?" />
              <ViewContent
                className="col-span-2"
                text={
                  <ul className="ml-4 list-outside list-disc">
                    {currentTaskAnalysis.training.map((training, index) => (
                      <li key={index} className="ml-1 text-gray-500">
                        <span className="text-gray-900">{training.description}</span>
                      </li>
                    ))}
                  </ul>
                }
              />
            </FieldWrapper>

            <FieldWrapper>
              <ViewLabel text="Emergency location" />
              <ViewContent text={currentTaskAnalysis.emergency_location} />
            </FieldWrapper>

            <FieldWrapper>
              <ViewLabel text="What PPE is required?" />
              <ViewContent
                className="col-span-2"
                text={
                  <ul className="ml-4 list-outside list-disc">
                    {currentTaskAnalysis.ppe.map((ppe, index) => (
                      <li key={index} className="ml-1 text-gray-500">
                        <span className="text-gray-900">{ppe.description}</span>
                      </li>
                    ))}
                  </ul>
                }
              />
            </FieldWrapper>

            <FieldWrapper>
              <ViewLabel text="What equipment will be used?" />
              <ViewContent
                className="col-span-2"
                text={
                  <ul className="ml-4 list-outside list-disc">
                    {currentTaskAnalysis.equipment.map((equipment, index) => (
                      <li key={index} className="ml-1 text-gray-500">
                        <span className="text-gray-900">{equipment.description}</span>
                      </li>
                    ))}
                  </ul>
                }
              />
            </FieldWrapper>

            <hr className="mt-4 border-gray-100" />

            <FieldWrapper>
              <h3 className="text-lg font-bold text-gray-900">Hazards</h3>
              <div className="space-y-2">
                {currentTaskAnalysis?.hazards.map((hazard, index) => (
                  <div key={index} className="grid grid-cols-2 gap-2 rounded rounded-lg border border-gray-100 bg-white p-4">
                    <p className="text-md col-span-2 font-semibold text-gray-900">{hazard.description}</p>
                    <ViewLabel text="Initial risk rating" />
                    <ViewLabel text="Residual risk rating" />
                    <ViewContent
                      text={
                        riskRatingValues?.find(
                          (riskRating) => riskRating.value === Math.ceil((hazard.initial_risk_rating || hazard.risk_rating) / 5) * 5,
                        )?.label
                      }
                    />
                    <ViewContent
                      text={riskRatingValues?.find((riskRating) => riskRating.value === Math.ceil(hazard.residual_risk_rating / 5) * 5)?.label}
                    />
                    <ViewLabel className="col-span-2" text="Risk controls" />
                    <ViewContent
                      className="col-span-2"
                      text={
                        <ul className="ml-4 list-outside list-disc">
                          {hazard.hazard_controls.map((control, index) => (
                            <li key={index} className="ml-1 text-gray-500">
                              <span className="text-gray-900">{control}</span>
                            </li>
                          ))}
                        </ul>
                      }
                    />
                    <ViewLabel className="col-span-2" text="Risks" />
                    <ViewContent
                      className="col-span-2"
                      text={
                        <ul className="ml-4 list-outside list-disc">
                          {hazard.risks?.map((risk, index) => (
                            <li key={index} className="ml-1 text-gray-500">
                              <span className="text-gray-900">{risk}</span>
                            </li>
                          ))}
                        </ul>
                      }
                    />
                  </div>
                ))}
              </div>
            </FieldWrapper>

            <FieldWrapper>
              <ViewLabel text="Site status" />
              <ViewContent text={currentTaskAnalysis.site_good ? 'Good to go' : 'Not good to go'} />
            </FieldWrapper>

            <FieldWrapper>
              <ViewLabel text="Additional Comments/Toolbox Talk" />
              <ViewContent text={currentTaskAnalysis.additional_comments} />
            </FieldWrapper>

            <FieldWrapper>
              <ViewLabel text="Checks" />
              <ViewContent
                className="col-span-2"
                text={
                  <ul className="list-outside">
                    {currentTaskAnalysis.checks?.map((check, index) => (
                      <li key={index} className="ml-1 flex space-x-1 text-gray-500">
                        <Icon path={mdiCheck} size={0.75} />
                        <span className="text-gray-900">{check.caption}</span>
                      </li>
                    ))}
                  </ul>
                }
              />
            </FieldWrapper>
            {currentTaskAnalysis?.media?.length > 0 && (
              <FieldWrapper>
                <ViewLabel text="Current Uploaded Images" />
                {currentTaskAnalysis?.media.map((image) => (
                  <ImageDisplay key={image.id} media={image} className="h-full w-full" />
                ))}
              </FieldWrapper>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewTaskAnalysis;
